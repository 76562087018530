/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'

import { Tooltip } from '../shared'

export const stockCountStatusOptions: Array<{
  label: string,
  value: ProductionOrderStatus,
}> = [
  { label: 'Draft', value: 'draft' },
  { label: 'Released', value: 'released' },
  { label: 'In progress', value: 'in_progress' },
  { label: 'Count done', value: 'count_done' },
  { label: 'Regulated', value: 'regulated' },
]

export const ALLOCATION_COLOR_GREEN = '#329a34'
export const ALLOCATION_COLOR_YELLOW = '#e6a725'
export const ALLOCATION_COLOR_RED = '#db4141'

export const AllocationStatusIndicator = ({ color, label, tip, ...rest }) => {
  return (
    <Tooltip id="stock-allocations" tip={tip}>
      <Container {...rest}>
        <AllocationStatusIndicatorHtml
          color={color}
          hasLabel={label !== undefined}
        />
        {label && (
          <AllocationStatusIndicatorLabel color={color}>
            {label}
          </AllocationStatusIndicatorLabel>
        )}
      </Container>
    </Tooltip>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const AllocationStatusIndicatorLabel = styled.span`
  color: ${({ color }) => color};
`

const AllocationStatusIndicatorHtml = styled.div`
  border-radius: 50%;
  background-color: ${({ color }) => color};
  display: inline-block;
  height: 5px;
  width: 5px;

  ${({ hasLabel }) =>
    hasLabel
      ? css`
          margin-right: 3px;
        `
      : ''}
`
