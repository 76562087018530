/* @flow */

import axios from 'axios'
import querystring from 'qs'

import { showMessage } from '../actions/toast'
import {
  attemptAccessTokenRefresh,
  setAccessToken,
  logoutUser,
} from '../actions/session'
import { TrackError } from '../exceptions/raven'

// by ensuring new fetch uses old token refresh we can ensure that we can mix-match requests
// without doing 2 refreshes. If we do 2 refreshes 1 will fail
import { requestNewAccessToken } from '../middleware/api'

let refreshTriesSinceLastSuccess = 0

let store
export const setReduxStore = (s: Object) => {
  store = s
}

let refreshPromise
const refreshUrl = '/login/refresh'

const apiBase = process.env.API_BASE_URL

type MethodType = 'DELETE' | 'GET' | 'POST' | 'PUT'
type InputOptions = {
  body?: Object | string,
  method: MethodType,
  statusCodeCallback?: {
    code: number,
    callback: Function,
  },
  query?: Object,
}

type FinalOptions = {
  baseURL: string,
  credentials: string,
  data?: Object,
  headers: { [string]: string },
  params?: Object,
  paramsSerializer?: Function,
  url: string,
}

const callApi = (
  url: string,
  inputOptions: InputOptions,
  attempt?: number = 1
) => {
  if (!apiBase || !process.env.VERSION) {
    throw new Error('Missing API base or version')
  }

  const options: FinalOptions = {
    ...inputOptions,
    baseURL: apiBase,
    credentials: '',
    headers: {},
    url: url,
  }

  if (attempt > 3) {
    throw new Error('Too many attempts')
  }

  let headers: { [string]: string } = {
    'content-type': 'application/json',
    'X-TraedeAppIdentifier': 'TraedeBrowserClient/' + process.env.VERSION,
  }

  const { token } = store.getState().session.accessToken
  if (token) {
    headers.authorization = 'Bearer ' + token
  }

  if (inputOptions.headers) {
    headers = {
      ...headers,
      ...inputOptions.headers,
    }
  }

  options.headers = headers
  options.credentials = 'same-origin'

  if (inputOptions.method == 'GET') {
    if (!inputOptions.query) {
      inputOptions.query = {}
    }

    inputOptions.query.rec_v4 = '1'
  }

  if (inputOptions.body && typeof inputOptions.body !== 'string') {
    options.data = inputOptions.body
  }
  if (inputOptions.query) {
    options.params = inputOptions.query
    options.paramsSerializer = params => querystring.stringify(params)
  }

  const ErrorObject = new Error(`API Error: ${inputOptions.method} ${url}`)

  return axios(options)
    .then(response => {
      refreshTriesSinceLastSuccess = 0

      return {
        payload: response.data,
        status: response.status,
      }
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.errors) {
        let wasCatchedByStatusCallback = false
        const errorCodes = error.response.data.errors.map(err => err.code)

        if (inputOptions.statusCodeCallback) {
          let statusCodeCallbacks = inputOptions.statusCodeCallback
          if (!Array.isArray(statusCodeCallbacks)) {
            statusCodeCallbacks = [statusCodeCallbacks]
          }

          for (let entry of statusCodeCallbacks) {
            if (errorCodes.includes(entry.code)) {
              wasCatchedByStatusCallback = true
              entry.callback(error.response)
              break
            }
          }
        }

        switch (error.response.status) {
          case 401:
            if (
              url !== refreshUrl &&
              typeof token === 'string' &&
              token.length > 0
            ) {
              if (!refreshPromise && refreshTriesSinceLastSuccess < 5) {
                refreshTriesSinceLastSuccess++

                refreshPromise = requestNewAccessToken(
                  store,
                  store.dispatch,
                  attemptAccessTokenRefresh
                )
              }

              return refreshPromise.then(response => {
                if (!response.error) {
                  refreshPromise = null

                  return callApi(url, inputOptions, attempt + 1)
                }

                return (
                  store
                    .dispatch(logoutUser())
                    // Ensure subscribers do not get an empty response, e.g. T512
                    .then(response => ({
                      error: true,
                      status:
                        response && response.payload
                          ? response.payload.status
                          : null,
                    }))
                )
              })
            }
            break
          case 422:
            if (!wasCatchedByStatusCallback) {
              store.dispatch(showMessage('info', error.response.data.errors))
            }
            break
          case 403:
            const ErrorObject = new Error(
              `Permission error: ${inputOptions.method} ${url}`
            )
            TrackError(ErrorObject, options)
            break
          default:
            TrackError(ErrorObject, options)
            break
        }
      } else if (error.request) {
        TrackError(ErrorObject, options)
      } else {
        TrackError(ErrorObject, options)
      }

      return {
        isCancel: axios.isCancel(error),
        error: true,
        payload: error.response
          ? error.response.data
          : {
              error: true,
              status: error.response ? error.response.status : null,
            },
        status: error.response ? error.response.status : 0,
      }
    })
}

type InputOptionsMinusMethod = $Diff<InputOptions, { method: MethodType }>

export default {
  delete: (url: string, o?: InputOptionsMinusMethod = {}) =>
    callApi(url, { ...o, method: 'DELETE' }),
  get: (url: string, o?: InputOptionsMinusMethod = {}) =>
    callApi(url, { ...o, method: 'GET' }),
  put: (url: string, o?: InputOptionsMinusMethod = {}) =>
    callApi(url, { ...o, method: 'PUT' }),
  post: (url: string, o?: InputOptionsMinusMethod = {}) =>
    callApi(url, { ...o, method: 'POST' }),
}

if (axios.interceptors) {
  axios.interceptors.response.use(undefined, err => {
    const { config, message } = err
    if (!config || !config.retry) {
      return Promise.reject(err)
    }
    // retry while Network timeout or Network Error
    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err)
    }
    config.retry -= 1
    const delayRetryRequest = new Promise(resolve => {
      setTimeout(() => {
        console.log('retry the request', config.url)
        resolve()
      }, config.retryDelay || 1000)
    })
    return delayRetryRequest.then(() => axios(config))
  })
}
