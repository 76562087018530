/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import LineText from '../../LineText'

import type { ColumnConfig } from '../../types'

type Options = {
  colli_not_included_in_value?: boolean,
  rows?: Array<string>,
}

const total_quantity: (options: Options) => ColumnConfig = ({
  aggregate_product = true,
  aggregate_property_by_row_key,
  colli_not_included_in_value = false,
  key = 'total_quantity',
  label = 'Total Q',
  multiply_assortments = false,
  rows,
}) => ({
  aggregate_product,
  key,
  label,
  matrix: true, // only use for matrix
  right: true,
  rows: rows,
  value: ({
    assortmentQuantity,
    rowKey,
    rowValuesByVariantId,
    rowValuesCollisByVariantId,
    variants,
  }) => {
    let totalQuantity = Object.keys(rowValuesByVariantId).reduce(
      (carry, variantId) => {
        const value = rowValuesByVariantId[variantId] || 0
        const valueColli = rowValuesCollisByVariantId[variantId] || 1

        let q
        // Sometimes we need to aggregate rows, where the rowValue is
        // an Array of objects (e.g. the `ordered` row in ProductionOrderTable)
        // aggregate_property_by_row_key will tell which property to
        // aggregate by
        if (
          Array.isArray(value) &&
          rowKey &&
          aggregate_property_by_row_key &&
          aggregate_property_by_row_key[rowKey]
        ) {
          q = value.reduce(
            (carry, row) =>
              (carry += row[aggregate_property_by_row_key[rowKey]]),
            0
          )

          // Aggregate to simple integer value
        } else {
          q = parseInt(value)

          if (colli_not_included_in_value) {
            q = q * parseInt(valueColli)
          }
        }

        const variant = variants ? variants.find(v => v.id == variantId) : null

        // If we have 2 assortments on same row with different assortment quantities
        if (variant && variant.assortment_quantity) {
          q = q * variant.assortment_quantity
        }

        return carry + q
      },
      0
    )

    return [totalQuantity]
  },
})

export default total_quantity
