/* @flow */

import * as React from 'react'
import axios from 'axios'
import { createApiHook, quickCreateHook } from '../hooks'
import groupBy from 'lodash/groupBy'
import isEqual from 'lodash/isEqual'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import isPlainObject from 'lodash/isPlainObject'
import querystring from 'qs'
import memo from 'memoize-one'

import { useDebounce, useLocalStorage } from '../../infrastructure/hooks'
import {
  clearCartV2,
  ensureWebshopSession,
  ensureAnonymousWebshopSession,
  getCartDataForDocument,
  getCartRows,
  getCartRowsV2,
  getCartTotalsV2,
  getCartView,
  getProductsAttributes,
  getWebshopProductsAttributesV2,
  getProducts,
  getProduct,
  getRelatedProducts,
  getRelatedProductsV2,
  getPublicBrand,
  getWebshopFavorites,
  getWebshopLandingForSession,
  getWebshopLanding,
  getWebshopPagesForSession,
  getWebshopPayStatus,
  getWebshopProductLabels,
  getWebshopProductV2,
  getWebshopNavigationLink,
  getWebshopNavigationLinks,
  getWebshopSessionList,
  getWebshopSplashForSession,
  getWebshopSplash,
  removeCartRows,
  updateCartV2,
  getWebshopCartsListAttributes,
} from './api'
import {
  useLinesReducer,
  prepareProductTableLinesForApi,
} from '../products/components/ProductTable2'

import { matchRuleBasedSettings } from '../entities/utilities'
import {
  DEFAULT_DIRECTION,
  DEFAULT_PAGE,
  createDefaultFilterValues,
  combineFixedFiltersWithFilterValues,
  allSortOptions,
} from './shared'

import { WebshopContext } from './shared'
import { msg, useRefValue } from '../shared'
import type {
  ConnectedBrand,
  Id,
  WebshopProductLabel,
  WebshopSession,
} from '../types'

export const usePublicBrand = createApiHook<ConnectedBrand | null>(
  (id?: Id, options?: Object) => {
    return getPublicBrand(id, options).then(response => {
      if (response.status === 200) {
        return {
          entity: response.payload.brand,
        }
      } else {
        return {
          error: true,
        }
      }
    })
  }
)

const { hook: useWebshopSession } = quickCreateHook<WebshopSession>(
  ensureWebshopSession,
  'session',
  null
)

export { useWebshopSession }

const {
  hook: useAnonymousWebshopSession,
  hookCached: useCachedAnonoymousWebshopSession,
  clearCache: clearAnonymousWebshopSessionCache,
} = quickCreateHook<WebshopSession>(
  ensureAnonymousWebshopSession,
  'session',
  null
)

export {
  useAnonymousWebshopSession,
  useCachedAnonoymousWebshopSession,
  clearAnonymousWebshopSessionCache,
}

const fetchWebshopProductLabelsForHook = (options?: Object) =>
  getWebshopProductLabels().then(response => {
    if (!response.error) {
      return {
        entity: response.payload.webshop_product_labels,
      }
    } else {
      return response
    }
  })

export const useWebshopProductLabels = createApiHook<
  Array<WebshopProductLabel>
>(fetchWebshopProductLabelsForHook, [])

const fetchWebshopPayStatusForHook = (
  brandId,
  webshopSessionId,
  token,
  webshop2
) =>
  getWebshopPayStatus(brandId, webshopSessionId, token, webshop2).then(
    response => {
      if (!response.error) {
        return {
          entity: response.payload,
        }
      } else {
        return response
      }
    }
  )

export const useWebshopPayStatus = createApiHook<Object | null>(
  fetchWebshopPayStatusForHook
)

const {
  hook: useShopProducts,
  hookCached: useCachedShopProducts,
  clearCache: clearShopProducts,
} = quickCreateHook<Product>(getProducts, 'products', [])

export { useShopProducts, useCachedShopProducts, clearShopProducts }

const {
  hook: useWebshopNavigationLink,
  hookCached: useCachedWebshopNavigationLink,
  clearCache: clearWebshopNavigationLinkCache,
} = quickCreateHook<Product>(getWebshopNavigationLink, null, null)

export {
  useWebshopNavigationLink,
  useCachedWebshopNavigationLink,
  clearWebshopNavigationLinkCache,
}

const {
  hook: useProductsAttributes,
  hookCached: useCacheProductsAttributes,
  clearCache: clearProductsAttributesCache,
} = quickCreateHook<Product>(getProductsAttributes, null, {})

export {
  useProductsAttributes,
  useCacheProductsAttributes,
  clearProductsAttributesCache,
}

const { hook: useWebshopProductsAttributesV2 } = quickCreateHook<Product>(
  getWebshopProductsAttributesV2,
  null,
  {}
)

export { useWebshopProductsAttributesV2 }

const {
  hook: useRelatedProducts,
  hookCached: useCacheRelatedProducts,
  clearCache: clearRelatedProductsCache,
} = quickCreateHook<Product>(getRelatedProductsV2, null, [])

export {
  useRelatedProducts,
  useCacheRelatedProducts,
  clearRelatedProductsCache,
}

const {
  hook: useProduct,
  hookCached: useCacheProduct,
  clearCache: clearProductCache,
} = quickCreateHook<Product>(getProduct, null, null)

export { useProduct, useCacheProduct, clearProductCache }

const {
  hook: useCartRows,
  hookCached: useCacheCartRows,
  clearCache: clearCartRowsCache,
} = quickCreateHook<CartRows>(getCartRows, null, [])

export { useCartRows, useCacheCartRows, clearCartRowsCache }

const {
  hook: useWebshopCartsListAttributes,
  hookCached: useCachedWebshopCartsListAttributes,
  clearCache: clearWebshopCartsListAttributes,
} = quickCreateHook(getWebshopCartsListAttributes, 'attributes')

export {
  useWebshopCartsListAttributes,
  useCachedWebshopCartsListAttributes,
  clearWebshopCartsListAttributes,
}

const {
  hook: useCartRowsV2,
  hookCached: useCacheCartRowsV2,
  clearCache: clearCartRowsV2Cache,
} = quickCreateHook<CartRows>(getCartRowsV2, 'rows', [])

export { useCartRowsV2, useCacheCartRowsV2, clearCartRowsV2Cache }

const {
  hook: useCartView,
  hookCached: useCacheCartView,
  clearCache: clearCartViewCache,
} = quickCreateHook<CartRows>(getCartView, null, {
  cart_rows: [],
  delivery_rows: [],
  product_table_products: [],
  prices: [],
})

export { useCartView, useCacheCartView, clearCartViewCache }

const {
  hook: useWebshopPagesForSession,
  hookCached: useCachedWebshopPagesForSession,
  clearCache: clearWebshopPagesForSession,
} = quickCreateHook<Product>(getWebshopPagesForSession, null, [])

export {
  useWebshopPagesForSession,
  useCachedWebshopPagesForSession,
  clearWebshopPagesForSession,
}

const { hook: useWebshopFavorites } = quickCreateHook<Product>(
  getWebshopFavorites,
  null,
  {
    products: [],
    total: 0,
  }
)

export { useWebshopFavorites }

const { hook: useWebshopNavigationLinks } = quickCreateHook<Product>(
  getWebshopNavigationLinks,
  null,
  []
)

export { useWebshopNavigationLinks }

const { hook: useWebshopSessionList } = quickCreateHook<Product>(
  getWebshopSessionList,
  'sessions',
  []
)

export { useWebshopSessionList }

const { hook: useWebshopSplash } = quickCreateHook<Product>(
  getWebshopSplash,
  'splash',
  []
)

export { useWebshopSplash }

const { hook: useWebshopSplashForSession } = quickCreateHook<Product>(
  getWebshopSplashForSession,
  'splash',
  null
)

export { useWebshopSplashForSession }

const { hook: useWebshopLanding } = quickCreateHook<Product>(
  getWebshopLanding,
  'landing',
  []
)

export { useWebshopLanding }

const { hook: useWebshopLandingForSession } = quickCreateHook<Product>(
  getWebshopLandingForSession,
  'landing',
  null
)

export { useWebshopLandingForSession }

const { hook: useWebshopProduct } = quickCreateHook<Product>(
  getWebshopProductV2,
  null,
  null
)

export { useWebshopProduct }

const { hook: useWebshopV2Totals } = quickCreateHook<Product>(
  getCartTotalsV2,
  null,
  {
    free_shipping_above: null,
    free_shipping_diff: 0,
    price: 0,
    quantity: 0,
    reached_free_shipping: false,
    shipping: null,
    shipping_vat: true,
  }
)

export { useWebshopV2Totals }

const { hook: useCartDataForDocument } = quickCreateHook<Product>(
  getCartDataForDocument,
  null,
  {
    lines: [],
  }
)

export { useCartDataForDocument }

export const useCartActions = (
  brandId: Id,
  webshopSessionId: Id,
  fetchFullView: boolean,
  saveAutomatically: boolean,
  filterNonBought: boolean,
  productId: ?Id,
  splitKey: ?string,
  singleVariantId: ?string
) => {
  const { brand, drops, refreshWebshopTotals } =
    React.useContext(WebshopContext)

  const cartFetchOptions = React.useMemo(
    () => [brandId, webshopSessionId, productId, splitKey, singleVariantId],
    [brandId, webshopSessionId, productId, splitKey, singleVariantId]
  )

  const [cartView, setCartView] = React.useState({
    isInitialized: false,
    cartRows: [],
    productTableProducts: [],
    deliveryStatus: [],
    prices: [],
  })
  const {
    isInitialized,
    cartRows,
    productTableProducts,
    deliveryStatus,
    prices,
  } = cartView

  // We are trying to prevent a waterfall performance issue. In the past we
  // would fetch cart rows -> then product table products -> then prices + delivery
  // This causes things to render slowly because it happens in separate requests
  // Instead we make 1 request that returns all 4
  React.useEffect(() => {
    const request = fetchFullView
      ? getCartView(...cartFetchOptions)
      : getCartRowsV2(...cartFetchOptions)

    request.then(response => {
      if (!response.error) {
        if (fetchFullView) {
          setCartView({
            isInitialized: true,
            cartRows: response.payload.cart_rows,
            productTableProducts: response.payload.product_table_products,
            deliveryStatus: response.payload.delivery_rows,
            prices: response.payload.prices,
          })
        } else {
          setCartView(s => ({
            ...s,
            isInitialized: true,
            cartRows: response.payload.rows,
          }))
        }
      }
    })
  }, [cartFetchOptions, fetchFullView, setCartView])

  const refreshCartRows = React.useCallback(() => {
    return getCartRowsV2(...cartFetchOptions).then(response => {
      if (!response.error) {
        setCartView(s => ({
          ...s,
          cartRows: response.payload.rows,
        }))
      }
    })
  }, [cartFetchOptions, setCartView])

  const [cartIsSaving, setCartIsSaving] = React.useState(false)
  const [cartIsDirty, setCartIsDirty] = React.useState(false)
  const [updatedLines, onLineChange] = useLinesReducer(cartRows)
  const debouncedUpdatedLines = useDebounce(updatedLines, 1000)
  const [dropsOfCart, setDropsOfCart] = React.useState([])

  React.useEffect(() => {
    onLineChange({
      type: 'set',
      lines: cartRows,
    })
  }, [cartRows, onLineChange])

  const updateCartLines = React.useCallback(
    (lines, isDirty = true) => {
      setCartIsSaving(true)

      const preparedLines = prepareProductTableLinesForApi(lines, ['quantity'])

      return updateCartV2(
        brandId,
        webshopSessionId,
        preparedLines,
        productId
      ).then(response => {
        setCartIsSaving(false)

        if (!response.error) {
          if (isDirty) {
            msg('success', 'Your cart was saved')
          } else {
            msg(
              'info',
              'Warning! You cart was saved but you did not make any changes to it. You have to enter how many pieces you want in the "Quantity" field'
            )
          }

          refreshWebshopTotals()

          setCartIsDirty(false)
        }

        return response
      })
    },
    [brandId, setCartIsSaving, setCartIsDirty, productId, webshopSessionId]
  )

  const onSaveEmployeeDiscountLines = React.useCallback(
    lines => {
      // update all local lines
      onLineChange({
        type: 'update',
        lines,
      })

      // persist lines
      if (!saveAutomatically) {
        return updateCartLines(lines)
      }

      return Promise.resolve({})
    },
    [onLineChange, saveAutomatically, updateCartLines]
  )

  React.useEffect(() => {
    if (!saveAutomatically) {
      return
    }

    if (!debouncedUpdatedLines || debouncedUpdatedLines.length === 0) {
      return
    }

    if (cartRows === debouncedUpdatedLines) {
      return
    }

    updateCartLines(updatedLines)
  }, [
    cartRows,
    debouncedUpdatedLines,
    refreshWebshopTotals,
    refreshCartRows,
    saveAutomatically,
    updateCartLines,
  ])

  const onClearCart = React.useCallback(
    callback => {
      return clearCartV2(brandId, webshopSessionId).then(response => {
        if (!response.error) {
          onLineChange({
            type: 'set',
            lines: [],
          })

          refreshWebshopTotals()

          callback()

          setCartIsDirty(false)
        }
      })
    },
    [
      brandId,
      refreshWebshopTotals,
      onLineChange,
      webshopSessionId,
      setCartIsDirty,
    ]
  )

  const totals = React.useMemo(() => {
    return updatedLines.reduce(
      (carry, row) => {
        const quantityOfRow = parseInt(row.quantity) * parseInt(row.colli)
        carry.quantity += quantityOfRow
        carry.price += quantityOfRow * row.net_price

        return carry
      },
      {
        quantity: 0,
        price: 0,
      }
    )
  }, [updatedLines])

  const wrappedOnLineChange = React.useCallback(
    (...args) => {
      setCartIsDirty(true)
      onLineChange(...args)
    },
    [onLineChange]
  )

  // When in overview we do not want all rows, only the ones we actually bought.
  // But in the product detail / quick cart views we want all available rows of a given product
  const filteredUpdatedCartLines = React.useMemo(() => {
    if (!filterNonBought) {
      return updatedLines
    }

    return updatedLines.filter(r => r.quantity > 0)
  }, [brand, updatedLines, filterNonBought])

  React.useEffect(() => {
    const dropIdsOfLines = new Set()

    for (let line of cartRows) {
      if (filterNonBought && line.quantity <= 0) {
        continue
      }

      dropIdsOfLines.add(line.drop_id)
    }

    const dropIds = Array.from(dropIdsOfLines)

    setDropsOfCart(
      dropIds
        .map(dropId => drops.find(drop => drop.id == dropId))
        .filter(drop => drop !== undefined)
    )
  }, [filterNonBought, drops, cartRows, setDropsOfCart])

  return {
    cartIsDirty,
    cartIsSaving,
    cartRows,
    cartView,
    clearCart: onClearCart,
    dropsOfCart,
    isInitialized,
    onLineChange: wrappedOnLineChange,
    onSaveEmployeeDiscountLines,
    refreshCartRows,
    totals,
    updatedLines: filteredUpdatedCartLines,
    updateCartLines,
  }
}

const CONTROLS_LOCAL_STORAGE_KEY = 'webshop_controls'
export const useWebshopSearch = (
  brand,
  webshopSession,
  attributes,
  fixedFilters,
  query,
  history,
  navigationLink,
  availableSortOptions,
  defaultSortValue,
  showOnlySubLevelCategoriesFilterOptions
) => {
  const webshopSettings: WebshopSettings = brand.settings.webshop_settings

  const showLabelsOptions = React.useMemo(() => {
    return brand.settings.webshop_settings.labels.map(label => ({
      value: label,
      label,
    }))
  }, [brand])

  // controls state
  const [showLabels, setShowLabels] = React.useState(
    showLabelsOptions.map(label => label.value)
  )
  const [{ sort, direction }, setSort] = React.useState(
    getSortAndDirectionFromSortValue(defaultSortValue)
  )
  const [{ limit }, setData, isControlsInitialized] = useLocalStorage(
    CONTROLS_LOCAL_STORAGE_KEY,
    {
      limit: webshopSettings.per_page_default || 16,
    }
  )

  const availableFilters = React.useMemo(() => {
    let availableFilters = webshopSettings.default_available_filters
    if (webshopSession.drop) {
      const availableFiltersRule = matchRuleBasedSettings(
        {
          drop_id: webshopSession.drop_id,
        },
        webshopSettings.available_filter_rules
      )

      if (availableFiltersRule) {
        availableFilters = availableFiltersRule.available_filters
      }
    }

    return availableFilters
  }, [webshopSession, webshopSettings])

  // search result state
  const [
    { isInitialized: isSearchResultsInitialized, isSearching, searchResults },
    setSearchData,
  ] = React.useState({
    isInitialized: false,
    isSearching: false,
    searchResults: { products: [], exact_matches: [], total: 0 },
  })

  const filterOptions = React.useMemo(() => {
    const filterOptions = {
      categories: [],
      collections: [],
      subbrands: [],
      ...attributes,
    }

    let drops = brand.drops.map(drop => ({
      ...drop,
      label: drop.name,
    }))

    if (webshopSession.drop_filter_code) {
      drops = drops.filter(drop => {
        const filterCode = drop.filter_code || ''

        return (
          filterCode.trim().toLowerCase() ===
          webshopSession.drop_filter_code.trim().toLowerCase()
        )
      })
    }

    const mappedDrops = []
    const dropDeliveries = []

    for (let drop of drops) {
      if (webshopSession.drop_id && webshopSession.drop_id != drop.id) {
        continue
      }

      let filteredDeliveries = drop.use_deliveries ? drop.deliveries : []

      if (navigationLink) {
        if (
          navigationLink.settings.drops &&
          navigationLink.settings.drops.length > 0
        ) {
          if (
            !navigationLink.settings.drops
              .map(dropId => parseInt(dropId))
              .includes(drop.id)
          ) {
            continue
          }
        }

        if (
          drop.use_deliveries &&
          navigationLink.settings.drop_deliveries &&
          navigationLink.settings.drop_deliveries.length > 0
        ) {
          const parsedDeliveryIds = navigationLink.settings.drop_deliveries.map(
            dropDeliveryId => parseInt(dropDeliveryId)
          )

          filteredDeliveries = filteredDeliveries.filter(delivery =>
            parsedDeliveryIds.includes(delivery.id)
          )
        }
      }

      const dropData = {
        ...drop,
        label: drop.name,
      }

      // we make 2 collections, one with parent and one without
      // in single-drop environment deliveries are the top option (i.e. no parent)
      // but in multi-drop they are children of the drop

      for (let delivery of filteredDeliveries) {
        dropDeliveries.push({
          ...delivery,
          parent: delivery.drop_id,
          label: delivery.name,
        })
      }

      mappedDrops.push(dropData)
    }

    filterOptions.drops = mappedDrops
    filterOptions.drop_deliveries = dropDeliveries

    return filterOptions
  }, [
    brand.drops,
    attributes,
    navigationLink,
    webshopSession.drop_id,
    webshopSession.drop_filter_code,
  ])

  const defaultFilterValues = React.useMemo(() => {
    const defaultFilterValues = {}

    for (let key of availableFilters) {
      defaultFilterValues[key] = key === 'noos' ? false : []

      if (key === 'drops') {
        defaultFilterValues.drop_deliveries = []
      }
    }

    return defaultFilterValues
  }, [availableFilters])

  // we only want filterValues to update if the query string gets updated with
  // an actual filter. For instance if split_key is updated or similar it should
  // not trigger a new search
  const filterQuery = React.useMemo(() => {
    const filterQuery = {}

    for (let key of availableFilters) {
      if (query[key]) {
        filterQuery[key] = query[key]
      }

      if (key === 'drops' && query.drop_deliveries) {
        filterQuery.drop_deliveries = query.drop_deliveries
      }
    }

    return filterQuery
  }, [availableFilters, query])

  const { page, filterValues } = React.useMemo(() => {
    const page = parseInt(query.page, 10) || DEFAULT_PAGE

    const filterValues = mapValues(defaultFilterValues, (value, filterType) => {
      const isNoos = /^noos$/.test(filterType)

      if (filterQuery[filterType]) {
        let values
        if (Array.isArray(filterQuery[filterType])) {
          values = filterQuery[filterType]
        } else if (isPlainObject(filterQuery[filterType])) {
          // parsed query can sometimes be an object, e.g. when arrayLimit in qs.parse
          // is overshot, see https://github.com/ljharb/qs#parsing-arrays
          values = Object.values(filterQuery[filterType])
        } else {
          values = [filterQuery[filterType]]
        }

        const mappedValues = values.map(value => {
          let parsed =
            /^attribute\.[^\.]+$/.test(filterType) ||
            /^product_custom_field\.[^\.]+$/.test(filterType) ||
            /^variant_custom_field\.[^\.]+$/.test(filterType)
              ? value
              : parseInt(value, 10)

          if (isNoos) {
            parsed = value == 1 ? true : false

            return parsed
          }

          return isNaN(parsed) ? value : parsed
        })

        if (isNoos) {
          return mappedValues[0] || false
        }

        return mappedValues
      }

      return value
    })

    return {
      filterValues,
      page,
    }
  }, [JSON.stringify(filterQuery), defaultFilterValues, query.page])

  const queryRefValue = useRefValue(query)
  const onUrlUpdate = React.useCallback(
    (updates, set = false) => {
      const query = set
        ? updates
        : {
            ...queryRefValue.current,
            ...updates,
          }

      const newUrl = `${location.pathname}?${querystring.stringify(query)}`

      history.push(newUrl)
    },
    [location, history, queryRefValue]
  )

  const onFilterClearClick = React.useCallback(
    filterType => {
      if (filterType && typeof filterType === 'string') {
        // clear filter by "filterType"
        onUrlUpdate({
          [filterType]: [],
          page: DEFAULT_PAGE,
        })
      } else if (!isEqual(filterValues, defaultFilterValues)) {
        const newFilterValues = { ...defaultFilterValues }

        // Keep the secondary navigation option when clearing filters
        if (
          webshopSettings.secondary_navigation_in_sidebar &&
          webshopSettings.secondary_navigation_by &&
          filterValues[webshopSettings.secondary_navigation_by]
        ) {
          newFilterValues[webshopSettings.secondary_navigation_by] =
            filterValues[webshopSettings.secondary_navigation_by]
        }

        // clear all
        onUrlUpdate({
          ...newFilterValues,
          page: DEFAULT_PAGE,
        })
      }
    },
    [onUrlUpdate, filterValues, defaultFilterValues, webshopSettings]
  )

  const onHandleFilterChange = React.useCallback(
    (filterType, option, checked) => {
      let newValue

      if (filterType === 'noos') {
        newValue = checked == true ? 1 : 0
      } else {
        newValue = filterValues[filterType] || []

        if (checked) {
          newValue = uniq([...newValue, option.id])
        } else {
          newValue = newValue.filter(id => id !== option.id)

          const subOptionsIds = filterOptions[filterType]
            .filter(o => o.parent === option.id)
            .map(o => o.id)

          // If user is unchecking top-level option, uncheck also all its suboptions
          if (subOptionsIds.length > 0) {
            newValue = newValue.filter(id => !subOptionsIds.includes(id))
          }
        }
      }

      const updated = {
        [filterType]: newValue,
        page: DEFAULT_PAGE,
      }

      // for drops, it's children are a separate property so we cannot uncheck it
      // like we do with sub categories
      if (
        filterType === 'drops' &&
        newValue == false &&
        filterValues.drop_deliveries
      ) {
        const dropDeliveryIds = filterOptions.drop_deliveries
          .filter(delivery => delivery.parent === option.id)
          .map(delivery => delivery.id)

        updated.drop_deliveries = filterValues.drop_deliveries.filter(
          dropDeliveryId => !dropDeliveryIds.includes(dropDeliveryId)
        )
      }

      onUrlUpdate(updated)
    },
    [filterOptions, filterValues, onUrlUpdate]
  )

  const onHandleSecondaryNavigationClick = React.useCallback(
    option => {
      onUrlUpdate({
        // Reset filter pills
        ...defaultFilterValues,
        [webshopSettings.secondary_navigation_by]: [option.id],
        page: DEFAULT_PAGE,
      })
    },
    [webshopSettings, onUrlUpdate, defaultFilterValues]
  )

  const onHandleFilterPillsChange = React.useCallback(
    (filterValues: Object) => {
      onUrlUpdate({
        ...filterValues,
        page: DEFAULT_PAGE,
      })
    },
    [onUrlUpdate]
  )

  const onPageChange = React.useCallback(
    page => {
      onUrlUpdate({ page })

      // Scroll to the top
      window.scroll(0, 0)
    },
    [onUrlUpdate]
  )

  const onLimitChange = React.useCallback(
    limit => {
      onPageChange(1)

      setData(s => ({ limit }))
    },
    [onPageChange, setData]
  )

  const onSortChange = React.useCallback(
    value => {
      setSort(getSortAndDirectionFromSortValue(value))
    },
    [setSort]
  )

  const {
    areFilterOptionsEmpty,
    attributeFilterOptions,
    categoriesFilterOptions,
    colorAttributeColors,
    colorAttributeName,
    moreThanOnePage,
    productCardsPerRow,
    productCardsMargin,
    showDescription,
    showTitle,
    showSortingOptions,
    sortOptions,
    sortWithDirection,
  } = React.useMemo(() => {
    let sortWithDirection = `${sort}_${direction}`

    // For following three we do not include direction
    if (
      sort === 'newest' ||
      sort === 'newarrivals' ||
      sort === 'bestsellers' ||
      sort === 'score'
    ) {
      sortWithDirection = sort
    }

    const sortOptions = allSortOptions.filter(
      ({ value }) => availableSortOptions.indexOf(value) !== -1
    )

    const moreThanOnePage = searchResults.total > searchResults.products.length

    const colorAttributeName = brand.settings.color_attribute
    const colorAttributeColors = brand.settings.color_attribute_colors

    const attributeFilterOptions = createAttributeFilterOptions(
      filterOptions,
      brand
    )

    const areFilterOptionsEmpty = Object.keys(filterOptions).every(
      filterType => filterOptions[filterType].length === 0
    )

    let categoriesFilterOptions = sortBy(filterOptions.categories, i =>
      i.label.toLowerCase()
    )

    if (showOnlySubLevelCategoriesFilterOptions) {
      categoriesFilterOptions = categoriesFilterOptions
        .filter(option => !!option.parent)
        .map(option => ({
          ...option,
          parent: null,
        }))
    }

    let productCardsPerRow = webshopSettings.product_cards_per_row
    let productCardsMargin = webshopSettings.product_cards_margin
    let showTitle = webshopSettings.product_search_title
    let showDescription = webshopSettings.product_search_description
    let showSortingOptions = true

    if (navigationLink) {
      showSortingOptions =
        navigationLink.settings.use_custom_product_sort !== true ||
        navigationLink.settings.disable_user_sort !== true

      if (navigationLink.settings.product_cards_per_row !== false) {
        productCardsPerRow = navigationLink.settings.product_cards_per_row
      }
      if (navigationLink.settings.product_cards_margin !== false) {
        productCardsMargin = navigationLink.settings.product_cards_margin
      }
    }

    return {
      areFilterOptionsEmpty,
      attributeFilterOptions,
      categoriesFilterOptions,
      colorAttributeColors,
      colorAttributeName,
      moreThanOnePage,
      productCardsPerRow,
      productCardsMargin,
      showDescription,
      showTitle,
      showSortingOptions,
      sortOptions,
      sortWithDirection,
    }
  }, [
    availableSortOptions,
    brand,
    direction,
    filterOptions,
    navigationLink,
    searchResults,
    sort,
    showOnlySubLevelCategoriesFilterOptions,
    webshopSettings,
  ])

  const onGoToProduct = React.useCallback(
    (productId: Id) => {
      if (navigationLink) {
        history.push(
          `/shop/${brand.id}/search/${
            navigationLink.id
          }/show/${productId}?${querystring.stringify(query)}`
        )
      } else {
        history.push(`/shop/${brand.id}/show/${productId}`)
      }
    },
    [brand, history, navigationLink, query]
  )

  // When user navigates to search page with different sorting settings,
  // make sure we switch to that
  React.useEffect(() => {
    setSort(getSortAndDirectionFromSortValue(defaultSortValue))
  }, [defaultSortValue, setSort])

  const cancelGetProductsRequestRef = React.useRef(null)

  React.useEffect(() => {
    const searchOptions = {
      currency: webshopSession.currency,
      filters: combineFixedFiltersWithFilterValues(fixedFilters, filterValues),
      page,
      limit,
      sort,
      direction,
      webshop_session_id: webshopSession.id,
      webshop_v2: 1,
    }

    if (navigationLink) {
      if (navigationLink.settings.drag_drop_sorting) {
        const customSortFallback =
          navigationLink.settings.custom_sort_fallback || 'name_asc'

        const [customSortFallbackKey, customSortFallbackDirection] =
          customSortFallback.split('_')

        searchOptions.custom_sort_fallback = customSortFallbackKey
        searchOptions.custom_sort_fallback_direction =
          customSortFallbackDirection
        searchOptions.webshop_navigation_link_id = navigationLink.id
      } else if (navigationLink.settings.use_custom_product_sort) {
        searchOptions.use_custom_product_sort = 1
      }
    }

    if (!isControlsInitialized) {
      return
    }

    setSearchData(s => ({
      ...s,
      isSearching: true,
    }))

    // In case the search is triggered when the previous getProducts request is
    // still running, cancel the previous request, because otherwise the previous
    // request could finish after the current one and user would get results for
    // previous search parameters
    if (cancelGetProductsRequestRef.current !== null) {
      cancelGetProductsRequestRef.current.call()
    }

    const cancelTokenSource = axios.CancelToken.source()
    cancelGetProductsRequestRef.current = () => cancelTokenSource.cancel()

    getProducts(brand.id, searchOptions, {
      cancelToken: cancelTokenSource.token,
      retry: 3,
    }).then(response => {
      if (response.error) {
        if (response.isCancel) {
          // Request cancelled, do nothing
          return
        }

        setSearchData(s => ({
          ...s,
          isSearching: false,
        }))

        return
      }

      cancelGetProductsRequestRef.current = null

      setSearchData(s => {
        const copy = { ...s, isInitialized: true, isSearching: false }

        if (!response.error) {
          copy.searchResults = excludeExactMatch(response.payload)
        }

        return copy
      })
    })
  }, [
    direction,
    filterValues,
    fixedFilters,
    isControlsInitialized,
    limit,
    navigationLink,
    page,
    setSearchData,
    sort,
    webshopSession,
  ])

  return {
    areFilterOptionsEmpty,
    availableFilters,
    attributeFilterOptions,
    categoriesFilterOptions,
    colorAttributeColors,
    colorAttributeName,
    defaultFilterValues,
    filterOptions,
    filterValues,
    limit,
    isSearching,
    isSearchResultsInitialized,
    moreThanOnePage,
    onFilterClearClick,
    onLimitChange,
    onGoToProduct,
    onHandleFilterChange,
    onHandleFilterPillsChange,
    onHandleSecondaryNavigationClick,
    onPageChange,
    onSortChange,
    page,
    productCardsPerRow,
    productCardsMargin,
    searchResults,
    setShowLabels,
    showDescription,
    showLabelsOptions,
    showLabels,
    showSortingOptions,
    showTitle,
    sortOptions,
    sortWithDirection,
  }
}

const getSortAndDirectionFromSortValue = (value: string) => {
  const splitted = value.split('_')

  let sort, direction
  if (splitted.length === 2) {
    sort = splitted[0]
    direction = splitted[1]
  } else {
    sort = splitted[0]
    direction = DEFAULT_DIRECTION
  }

  return { sort, direction }
}

const createAttributeFilterOptions = memo((filterOptions, brand) => {
  const attributeFilterOptions = []
  for (let key in filterOptions) {
    const matches = key.match(/^attribute\.([^\.]+)$/)

    if (matches && filterOptions[key].length > 0) {
      attributeFilterOptions.push({
        key,
        label: matches[1],
        options: filterOptions[key],
      })
    }
  }

  return sortBy(attributeFilterOptions, attribute => {
    if (brand.settings.product_table.horizontal_attribute === attribute) {
      return 'zzzzzzzzzzz'
    }

    return attribute.label
  })
})

const excludeExactMatch = ({
  products = [],
  exact_matches = [],
  total = 0,
}) => {
  const hasExactMatches = exact_matches.length > 0

  return {
    products: !hasExactMatches
      ? products
      : products.filter(({ id }) => {
          return !exact_matches.find(product => product.id === id)
        }),
    exact_matches,
    total,
  }
}
