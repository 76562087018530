/* @flow */

import api from '../api'

import type { ApiResponse, BrunoRequestOptions, Id } from '../types'
import type { Report, Sheet, Workspace } from './components/Generator/types'

export const createReport = (
  options
): Promise<ApiResponse<{ report: Report }>> =>
  api.post('/reports', {
    body: options,
  })

export const exportReportToExcel = (sheetId: Id, tableRows: Array<Object>) =>
  api.post('/reports/export', {
    body: {
      sheet_id: sheetId,
      rows: tableRows,
    },
  })

export const getSheets = (
  options
): Promise<ApiResponse<{ sheets: Array<Sheet> }>> =>
  api.get('/reports/sheets', {
    query: options,
  })

export const getWorkspaces = (): Promise<
  ApiResponse<{ workspaces: Array<Workspace> }>
> => api.get('/reports/workspaces')

// Sales goals

export const getCustomerSalesGoals = (customerId: Id) =>
  api.get(`/reports/sales-goals-dashboards/customers/${customerId}`)

// Revenue

export const generateRevenueReport = options =>
  api.post('/reports/revenue', {
    body: options,
  })

export const exportRevenueReport = (options, sectionsMode) =>
  api.post(`/reports/revenue/export`, {
    body: {
      options,
      sections_mode: sectionsMode,
    },
  })

// Rankings

export const generateRankingsReport = options =>
  api.post('/reports/rankings', {
    body: options,
  })

export const exportRankingsReport = options =>
  api.post(`/reports/rankings/export`, {
    body: {
      options,
    },
  })

// Warehousing

export const generateWarehousingReport = options =>
  api.post('/reports/warehousing', {
    body: options,
  })

// Claim

export const generateClaimsReport = options =>
  api.post('/reports/claims', {
    body: options,
  })

export const exportClaimsReport = (options, sectionsMode) =>
  api.post(`/reports/claims/export`, {
    body: {
      options,
      sections_mode: sectionsMode,
    },
  })

// Intrastat

export const getIntrastatReports = (options?: BrunoRequestOptions) =>
  api.get('/reports/intrastat', {
    query: options,
  })

export const createIntrastatReport = (fromDate: string, toDate: string) =>
  api.post('/reports/intrastat', {
    body: {
      from_date: fromDate,
      to_date: toDate,
    },
  })

export const downloadIntrastatReport = (id: Id, format: 'excel' | 'csv') =>
  api.post(`/reports/intrastat/${id}/download-intrastat`, {
    body: {
      format,
    },
  })

export const downloadEuSalesExVatReport = (id: Id, format: 'excel' | 'csv') =>
  api.post(`/reports/intrastat/${id}/download-eu-sales-ex-vat`, {
    body: {
      format,
    },
  })

export const removeIntrastatReport = (id: Id) =>
  api.delete(`/reports/intrastat/${id}`)

// One stop shop

export const getOneStopShopReports = (options?: BrunoRequestOptions) =>
  api.get('/reports/one-stop-shop', {
    query: options,
  })

export const createOneStopShopReport = (fromDate: string, toDate: string) =>
  api.post('/reports/one-stop-shop', {
    body: {
      from_date: fromDate,
      to_date: toDate,
    },
  })

export const downloadOneStopShopReport = (id: Id, format: 'excel' | 'csv') =>
  api.post(`/reports/one-stop-shop/${id}/download`, {
    body: {
      format,
    },
  })

export const removeOneStopShopReport = (id: Id) =>
  api.delete(`/reports/one-stop-shop/${id}`)

// Commission

export const getCommissionReportList = (options?: DataTableRequestOptions) =>
  api.get('/reports/commission/list', {
    query: options,
  })

export const getCommissionReportListAttributes = () =>
  api.get('/reports/commission/attributes')

export const createCommissionReport = data =>
  api.post('/reports/commission', {
    body: {
      report: data,
    },
  })

export const updateCommissionReport = (id: Id, data) =>
  api.put(`/reports/commission/${id}`, {
    body: {
      report: data,
    },
  })

export const deleteCommissionReport = (id: Id) =>
  api.delete(`/reports/commission/${id}`)

export const getCommissionReport = (id: Id, options?: BrunoRequestOptions) =>
  api.get(`/reports/commission/${id}`, {
    query: options,
  })

export const getCommissionReportForEditor = (
  id: Id,
  options?: BrunoRequestOptions
) =>
  api.get(`/reports/commission/${id}/for-editor`, {
    query: options,
  })

export const exportCommissionReportExcel = (id: Id) =>
  api.post(`/reports/commission/${id}/export-excel`)

export const exportCommissionReportPdf = (id: Id, orientation: string) =>
  api.post(`/reports/commission/${id}/export-pdf`, { body: { orientation } })

// cancellations

export const generateCancellationsReport = options =>
  api.post('/reports/cancellations', {
    body: options,
  })

// unpaid invoices flow

export const getUnpaidInvoicesFlowDashboard = (axiosOptions, options) =>
  api.get('/reports/unpaid-invoices-flow', {
    query: options,
    ...axiosOptions,
  })

export const addInvoicePaymentFlowComments = (axiosOptions, comments) =>
  api.post('/reports/unpaid-invoices-flow/comments', {
    body: {
      comments,
    },
    ...axiosOptions,
  })

export const changeInvoicePaymentFlowStatuses = (axiosOptions, changes) =>
  api.post('/reports/unpaid-invoices-flow/change-statuses', {
    body: {
      changes,
    },
    ...axiosOptions,
  })

export const sendInvoicePaymentFlowReminders = (
  axiosOptions,
  mode,
  reminders
) =>
  api.post('/reports/unpaid-invoices-flow/send-reminders', {
    body: {
      mode,
      reminders,
    },
    ...axiosOptions,
  })

// Workspaces

export const getReportingWorkspaces = options =>
  api.get('/reports/workspaces', {
    query: options,
  })

export const createReportingWorkspace = workspace =>
  api.post('/reports/workspaces', {
    body: {
      workspace,
    },
  })

export const updateReportingWorkspace = (workspaceId: Id, workspace) =>
  api.put(`/reports/workspaces/${workspaceId}`, {
    body: {
      workspace,
    },
  })

export const deleteReportingWorkspace = (workspaceId: Id) =>
  api.delete(`/reports/workspaces/${workspaceId}`)

// Sheets

export const getReportingSheets = options =>
  api.get('/reports/sheets', {
    query: options,
  })

export const createReportingSheet = sheet =>
  api.post('/reports/sheets', {
    body: {
      sheet,
    },
  })

export const updateReportingSheet = (sheetId: Id, sheet) =>
  api.put(`/reports/sheets/${sheetId}`, {
    body: {
      sheet,
    },
  })

export const deleteReportingSheet = (sheetId: Id) =>
  api.delete(`/reports/sheets/${sheetId}`)

// Dashboards

export const getDashboards = options =>
  api.get('/dashboards', {
    query: options,
  })

export const getDashboardSettings = options =>
  api.get('/dashboard-settings', {
    query: options,
  })

export const createDashboard = data =>
  api.post('/dashboards', {
    body: {
      dashboard: data,
    },
  })

export const updateDashboard = (dashboardId, data) =>
  api.put(`/dashboards/${dashboardId}`, {
    body: {
      dashboard: data,
    },
  })

export const deleteDashboard = dashboardId =>
  api.delete(`/dashboards/${dashboardId}`)
