/* @flow */

import { useEffect, useState } from 'react'
import { createApiHook, createDataCache, quickCreateHook } from '../hooks'

import {
  getCostTypes,
  getSupplier,
  getSuppliers,
  getSupplierAttributes,
  getDropOrderListOfProductionOrder,
  getOrderedQuantitiesOfProductionOrder,
  getOverheadCostInvoice,
  getOverheadCostInvoiceAttributes,
  getProductionPrepayment,
  getProductionPrepaymentAttributes,
  getProductionInvoiceCostTypes,
  getProductionOrder,
  getProductionOrders,
  getProductionOrderDeliveryNote,
  getProductionOrderDeliveryNoteAttributes,
  getProductionOrderDeliveryNoteCostPricesOverview,
  getProductionOrderDeliveryNoteForScanner,
  getProductionOrderDeliveryNoteList,
  getProductionOrderDeliveryNotes,
  getProductionOrderGroup,
  getProductionOrderListAttributes,
  getProductionOrderList,
  getProductionOrderStraightToCustomerAvailableOrders,
} from './api'
import type {
  BrunoRequestOptions,
  DataTableAttribute,
  Id,
  Supplier,
} from '../types'

type State = {
  isLoading: boolean,
  suppliers: Array<Supplier>,
}

export const useSuppliers = createApiHook<Array<Supplier>>(
  (options?: Object) =>
    getSuppliers(options).then(response => {
      if (!response.error) {
        return {
          entity: response.payload.suppliers,
        }
      } else {
        return response
      }
    }),
  []
)

export const useProductionOrderList = createApiHook<Array<Supplier>>(
  (options?: Object) =>
    getProductionOrderList(options).then(response => {
      if (!response.error) {
        return {
          entity: response.payload.production_orders,
        }
      } else {
        return response
      }
    }),
  []
)

const {
  cache: { clearCache: clearSuppliersCache },
  hook: useCachedSuppliers,
} = createDataCache(useSuppliers)

const fetchSupplierForHook = (supplierId: Id, options?: BrunoRequestOptions) =>
  getSupplier(supplierId, options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.supplier,
      }
    } else {
      return response
    }
  })

export const useSupplier = createApiHook<Supplier | null>(fetchSupplierForHook)

const fetchSupplierAttributesForHook = (options?: Object) =>
  getSupplierAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const useSupplierAttributes = createApiHook<Array<DataTableAttribute>>(
  fetchSupplierAttributesForHook,
  []
)

const fetchProductionOrderAttributesForHook = (options?: Object) =>
  getProductionOrderListAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const useProductionOrderAttributes = createApiHook<
  Array<DataTableAttribute>
>(fetchProductionOrderAttributesForHook, [])

const fetchProductionOrderDeliveryNoteAttributesForHook = (options?: Object) =>
  getProductionOrderDeliveryNoteAttributes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.attributes,
      }
    } else {
      return response
    }
  })

export const useProductionOrderDeliveryNoteAttributes = createApiHook<
  Array<DataTableAttribute>
>(fetchProductionOrderDeliveryNoteAttributesForHook, [])

const fetchCostTypesForHooks = (options?: Object) =>
  getCostTypes(options).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.cost_types,
      }
    } else {
      return response
    }
  })

export const useCostTypes = createApiHook<Array<CostType>>(
  fetchCostTypesForHooks,
  []
)

const {
  cache: { clearCache: clearCostTypesCache },
  hook: useCachedCostTypes,
} = createDataCache(useCostTypes)

export {
  clearCostTypesCache,
  clearSuppliersCache,
  useCachedCostTypes,
  useCachedSuppliers,
}

export const useProductionOrderDeliveryNoteList = createApiHook<
  Array<Supplier>
>(
  (options?: Object) =>
    getProductionOrderDeliveryNoteList(options).then(response => {
      if (!response.error) {
        return {
          entity: response.payload.production_order_delivery_notes,
        }
      } else {
        return response
      }
    }),
  []
)

const fetchProductionOrderDeliveryNoteForHooks = (
  productionOrderDeliveryNoteId,
  options?: Object
) => {
  return getProductionOrderDeliveryNote(
    productionOrderDeliveryNoteId,
    options
  ).then(response => {
    if (!response.error) {
      return {
        entity: response.payload.production_order_delivery_note,
      }
    } else {
      return response
    }
  })
}

export const useProductionOrderDeliveryNote =
  createApiHook<ProductionOrderDeliveryNote>(
    fetchProductionOrderDeliveryNoteForHooks,
    null
  )

const {
  hook: useProductionInvoiceCostTypes,
  hookCached: useCachedProductionInvoiceCostTypes,
  clearCache: clearProductionInvoiceCostTypesCache,
} = quickCreateHook(getProductionInvoiceCostTypes, 'cost_types')

export {
  useProductionInvoiceCostTypes,
  useCachedProductionInvoiceCostTypes,
  clearProductionInvoiceCostTypesCache,
}

const {
  hook: useProductionOrder,
  hookCached: useCachedProductionOrder,
  clearCache: clearProductionOrderCache,
} = quickCreateHook(getProductionOrder, 'production_order')

export {
  useProductionOrder,
  useCachedProductionOrder,
  clearProductionOrderCache,
}

const {
  hook: useProductionOrders,
  hookCached: useCachedProductionOrders,
  clearCache: clearProductionOrdersCache,
} = quickCreateHook(getProductionOrders, 'production_orders', [])

export {
  useProductionOrders,
  useCachedProductionOrders,
  clearProductionOrdersCache,
}

const {
  hook: useProductionOrderDeliveryNotes,
  hookCached: useCachedProductionOrderDeliveryNotes,
  clearCache: clearProductionOrderDeliveryNotesCache,
} = quickCreateHook(
  getProductionOrderDeliveryNotes,
  'production_order_delivery_notes',
  []
)

export {
  useProductionOrderDeliveryNotes,
  useCachedProductionOrderDeliveryNotes,
  clearProductionOrderDeliveryNotesCache,
}

const {
  hook: useProductionOrderGroup,
  hookCached: useCachedProductionOrderGroup,
  clearCache: clearProductionOrderGroupCache,
} = quickCreateHook(getProductionOrderGroup, 'production_order_group')

export {
  useProductionOrderGroup,
  useCachedProductionOrderGroup,
  clearProductionOrderGroupCache,
}

const {
  hook: useProductionOrderDeliveryNoteForScanner,
  hookCached: useCachedProductionOrderDeliveryNoteForScanner,
  clearCache: clearProductionOrderDeliveryNoteForScannerCache,
} = quickCreateHook(
  getProductionOrderDeliveryNoteForScanner,
  'production_order_delivery_note'
)

export {
  useProductionOrderDeliveryNoteForScanner,
  useCachedProductionOrderDeliveryNoteForScanner,
  clearProductionOrderDeliveryNoteForScannerCache,
}

const { hook: useOrderListOfProductionOrder } = quickCreateHook(
  getDropOrderListOfProductionOrder,
  'orders',
  []
)

export { useOrderListOfProductionOrder }

const { hook: useOrderedQuantitiesOfProductionOrder } = quickCreateHook(
  getOrderedQuantitiesOfProductionOrder,
  'lines',
  []
)

export { useOrderedQuantitiesOfProductionOrder }

const { hook: useOverheadCostInvoice } = quickCreateHook(
  getOverheadCostInvoice,
  'overhead_cost_invoice',
  null
)

export { useOverheadCostInvoice }

const { hook: useOverheadCostInvoicesAttributes } = quickCreateHook(
  getOverheadCostInvoiceAttributes,
  'attributes',
  []
)

export { useOverheadCostInvoicesAttributes }

const { hook: useProductionPrepayment } = quickCreateHook(
  getProductionPrepayment,
  'production_prepayment',
  null
)

export { useProductionPrepayment }

const { hook: useProductionPrepaymentsAttributes } = quickCreateHook(
  getProductionPrepaymentAttributes,
  'attributes',
  []
)

export { useProductionPrepaymentsAttributes }

const { hook: useProductionOrderDeliveryNoteCostPricesOverview } =
  quickCreateHook(
    getProductionOrderDeliveryNoteCostPricesOverview,
    'cost_prices',
    null,
    { v2: true }
  )

export { useProductionOrderDeliveryNoteCostPricesOverview }

const { hook: useProductionOrderStraightToCustomerAvailableOrders } =
  quickCreateHook(
    getProductionOrderStraightToCustomerAvailableOrders,
    'orders',
    [],
    { v2: true }
  )

export { useProductionOrderStraightToCustomerAvailableOrders }
