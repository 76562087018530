/* @flow */

import * as React from 'react'

import ProductTableRowsDeliveryList from './Components/ProductTableRowsDeliveryList'
import InventoryIndicator from './InventoryIndicator'

import type { ColumnConfig } from '../../types'

type Options = {
  isCustomerFacingDeliveryStatus?: boolean,
}

const availability: (options: Options) => ColumnConfig = ({
  defaultRender,
  label = 'Available',
  isCustomerFacingDeliveryStatus = false,
}) => ({
  data_source: 'availability',
  key: 'availability',
  label,
  render: ({
    brand,
    config,
    context,
    data,
    onClearDataCache,
    lines,
    variant,
    value,
    userEntity,
  }) => {
    return (
      <AvailabilityStatus
        brand={brand}
        config={config}
        context={context}
        data={data}
        defaultRender={defaultRender}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        lines={lines}
        onClearDataCache={onClearDataCache}
        userEntity={userEntity}
        value={value}
        variant={variant}
      />
    )
  },
  style: ({ matrix }) => {
    const style = {}

    if (matrix) {
      style.verticalAlign = 'top'
    }
    if (!isCustomerFacingDeliveryStatus) {
      style.width = '150px'
    }

    return style
  },
})

export default availability

const AvailabilityStatus = ({
  brand,
  config,
  defaultRender,
  isCustomerFacingDeliveryStatus,
  value,
  userEntity,
}) => {
  const status = value ? value[0] : null

  const available = React.useMemo(() => {
    if (!status) {
      return 0
    }

    return status.immediately_available
  }, [status])

  const inventoryIndicatorRule = React.useMemo(() => {}, [
    brand.settings.stock_settings.inventory_indicator_colors,
    available,
    isCustomerFacingDeliveryStatus,
  ])

  const availableWidthForRender = React.useMemo(() => {
    // Approximate width for image column, row label, or margin around product table,
    // it is fine that it is not exact
    const approxStaticPartsWidth = 250

    const availableWidthForNonStaticColumns =
      window.innerWidth - approxStaticPartsWidth

    // maxHorizontalColumns is the number of columns from horizontal attributes,
    // productTableContext.columns.length is then the number of columns for row on the right
    // of the horizontal attributes
    const nonStaticColumnsCount =
      config.maxHorizontalColumns + config.columns.length

    // This returns mean width for non-static columns, which is not exactly the real
    // available width, but it is good enough measure
    return availableWidthForNonStaticColumns / nonStaticColumnsCount
  }, [config.maxHorizontalColumns, config.columns.length])

  if (!status) {
    if (defaultRender) {
      return defaultRender()
    }

    return null
  }

  if (status.deliveries.length === 0 && !status.lead_time) {
    return null
  }

  if (isCustomerFacingDeliveryStatus && inventoryIndicatorRule) {
    return <InventoryIndicator brand={brand} rule={inventoryIndicatorRule} />
  }

  return (
    <ProductTableRowsDeliveryList
      availableWidthForRender={availableWidthForRender}
      brand={brand}
      isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
      status={status}
      showStockAsIndicator={isCustomerFacingDeliveryStatus ? true : null}
      userEntity={userEntity}
    />
  )
}
