/* @flow */

import api from '../api'

import type {
  ApiResponse,
  CartRow,
  Currency,
  Id,
  WebshopProductRequestOptions,
} from '../types'
import type { AvailableDelivery } from './types'

// Webshop

export const ensureWebshopSession = (
  brandId: Id,
  linesheetId?: Id,
  useWebshopSessionId?: ?Id
) =>
  api.post(`/webshop/${brandId}/sessions`, {
    body: {
      linesheetId,
      use_webshop_session_id: useWebshopSessionId,
    },
  })

export const getWebshopSessionList = (brandId: Id) =>
  api.get(`/webshop/${brandId}/sessions/list`)

export const ensureAnonymousWebshopSession = (brandId: Id, extraData) =>
  api.post(`/webshop/${brandId}/sessions/anonymous`, {
    body: {
      extra_data: extraData,
    },
  })

export const getWebshopCurrencies = (
  entityId: Id
): Promise<ApiResponse<Array<Currency>>> =>
  api.get(`/webshop/${entityId}/currencies`)

export const getWebshopNavigationLink = (
  brandId: Id,
  linkId: Id,
  webshopSessionId: Id
) =>
  api.get(`/webshop/${brandId}/navigation/${linkId}`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopNavigationLinks = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/webshop/${brandId}/navigation`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getRelatedProducts = (
  brandId: Id,
  productId: Id,
  options: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products/${productId}/related-products`, {
    query: options,
  })

export const getRelatedProductsV2 = (
  brandId: Id,
  productId: Id,
  options: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products-v2/${productId}/related-products`, {
    query: options,
  })

export const getWebshopDrops = (brandId: Id) =>
  api.get(`/webshop/${brandId}/drops`)

export const getWebshopPagesForSession = (brandId: Id) =>
  api.get(`/webshop/${brandId}/pages`)

export const createWebshopSession = (brandId: Id, data: Object) =>
  api.post(`/webshop/${brandId}/sessions/create-new`, {
    body: {
      session: data,
    },
  })

export const updateWebshopSession = (
  brandId: Id,
  sessionId: Id,
  data: Object
) =>
  api.put(`/webshop/${brandId}/sessions/${sessionId}`, {
    body: {
      session: data,
    },
  })

export const deleteWebshopSession = (brandId: Id, webshopSessionId: Id) =>
  api.delete(`/webshop/${brandId}/sessions/${webshopSessionId}`)

export const getOrderSelectionSiblingOrders = (brandId, orderId) =>
  api.get(`/webshop/${brandId}/sessions/order-selection-sibling-orders`, {
    query: {
      order_id: orderId,
    },
  })

export const ensureWebshopSessionFromOrderSelection = (
  brandId: Id,
  orderIds: Array<Id>
) =>
  api.post(`/webshop/${brandId}/sessions/ensure-from-order-selection`, {
    body: {
      order_ids: orderIds,
    },
  })

export const getWebshopFavorites = (brandId: Id) =>
  api.get(`/webshop/${brandId}/favorites-v2`)

export const trackOpened = (brandId: Id) =>
  api.post(`/webshop/${brandId}/track-opened`)

export const trackWebshopSessionOpened = (brandId: Id, webshopSessionId: Id) =>
  api.post(`/webshop/${brandId}/track-session-opened`, {
    body: {
      webshop_session_id: webshopSessionId,
    },
  })

// Cart

export const getCartDeliveries = (
  brandId: Id,
  currency: Currency,
  productIds: Array<Id>
): Promise<
  ApiResponse<{ available_deliveries: { [string]: Array<AvailableDelivery> } }>
> =>
  api.post(`/cart/${brandId}/deliveries`, {
    body: {
      currency: currency,
      product_ids: productIds,
    },
  })

export const getCartRows = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id,
  productId?: Id
): Promise<ApiResponse<{ rows: Array<CartRow> }>> =>
  api.get(`/cart/${brandId}`, {
    query: {
      currency,
      productId,
      webshop_session_id: webshopSessionId,
    },
  })

export const getCartRowsV2 = (
  brandId: Id,
  webshopSessionId: Id,
  productId?: Id,
  splitKey?: ?string
): Promise<ApiResponse<{ rows: Array<CartRow> }>> =>
  api.get(`/cart-v2/${brandId}`, {
    query: {
      product_id: productId,
      webshop_session_id: webshopSessionId,
      split_key: splitKey,
    },
  })

export const getCartTotalsV2 = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/cart-v2/${brandId}/totals`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getCartView = (
  brandId: Id,
  webshopSessionId: Id,
  productId?: Id,
  splitKey: ?string
) =>
  api.get(`/cart-v2/${brandId}/cart-view`, {
    query: {
      product_id: productId,
      webshop_session_id: webshopSessionId,
      split_key: splitKey,
    },
  })

export const updateCart = (
  brandId: Id,
  currency: Currency,
  rows: Array<NonPersistedCartRow>,
  webshopSessionId: Id
) =>
  api.put(`/cart/${brandId}`, {
    body: {
      currency,
      rows,
      webshop_session_id: webshopSessionId,
    },
  })

export const updateCartV2 = (
  brandId: Id,
  webshopSessionId: Id,
  rows: Array<NonPersistedCartRow>,
  inputProductId: ?Id = null
) =>
  api.put(`/cart-v2/${brandId}`, {
    body: {
      rows,
      webshop_session_id: webshopSessionId,
      input_product_id: inputProductId,
    },
  })

export const updateCartDeliveries = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id,
  data: Array<{ cart_row_id: Id, delivery_mode: number }>
) =>
  api.put(`/cart/${brandId}/deliveries`, {
    body: {
      currency: currency,
      deliveries: data,
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopCartList = (
  options?: DataTableRequestOptions
): Promise<
  ApiResponse<{ linesheets: Array<LinesheetElastic>, total: number }>
> =>
  api.get('/webshop-cart/list', {
    query: options,
  })

export const getWebshopCartsListAttributes = (): Promise<
  ApiResponse<{ attributes: Array<DataTableAttribute> }>
> => api.get('/webshop-carts/attributes')

export const fillCartFromOrder = (
  brandId: Id,
  orderId: Id,
  clearNonEmptyCart: boolean
): Promise<ApiResponse<void>> =>
  api.post(`/cart/${brandId}/fill-from-order`, {
    body: {
      order_id: orderId,
      clear_non_empty_cart: clearNonEmptyCart,
    },
    // Turn off default 422 error handling, we will handle it ourselves
    statusCodeCallback: {
      code: 422,
      callback: () => {},
    },
  })

export const removeCartRows = (
  brandId: Id,
  currency: Currency,
  rows: Array<NonPersitedCartRow>,
  webshopSessionId: Id
) =>
  api.delete(`/cart/${brandId}`, {
    query: {
      currency,
      rows,
      webshop_session_id: webshopSessionId,
    },
  })

export const clearCart = (
  brandId: Id,
  currency: Currency,
  webshopSessionId: Id
) =>
  api.delete(`/cart/${brandId}`, {
    query: {
      currency,
      webshop_session_id: webshopSessionId,
    },
  })

export const clearCartV2 = (brandId: Id, webshopSessionId: Id) =>
  api.delete(`/cart-v2/${brandId}`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getCartDataForDocument = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/webshop/${brandId}/document-data`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const exportCartToExcel = (brandId: Id, webshopSessionId: Id) =>
  api.post(`/webshop/${brandId}/export-excel`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const exportCartToOrdersheet = (brandId: Id, webshopSessionId: Id) =>
  api.post(`/webshop/${brandId}/export-ordersheet`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const exportCartToPdf = (brandId: Id, webshopSessionId: Id) =>
  api.post(`/webshop/${brandId}/export-pdf`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const exportPdfForWebshopCartList = (brandId: Id, webshopSessionId: Id) =>
  api.post(`/webshop/${brandId}/export-pdf-webshop-cart-list`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })


// Products

export const getProducts = (
  brandId: Id,
  options?: WebshopProductRequestOptions,
  axiosOptions?: Object = {}
) =>
  api.post(`/webshop/${brandId}/search-v2`, {
    body: options,
    ...axiosOptions,
  })

export const getProductsAttributes = (
  brandId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/attributes`, {
    query: options,
  })

export const getWebshopProductsAttributesV2 = (
  brandId: Id,
  options?: WebshopProductRequestOptions,
  axiosOptions?: Object = {}
) =>
  api.get(`/webshop/${brandId}/attributes-v2`, {
    query: options,
    ...axiosOptions,
  })

export const getProduct = (
  brandId: Id,
  productId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products/${productId}`, {
    query: options,
  })

export const getWebshopProductV2 = (
  brandId: Id,
  productId: Id,
  options?: WebshopProductRequestOptions
) =>
  api.get(`/webshop/${brandId}/products-v2/${productId}`, {
    query: options,
  })

export const getWebshopPrices = (
  brandId: Id,
  webshopSessionId: Id,
  productIds: Array<Id>
) =>
  api.post(`/webshop/${brandId}/prices`, {
    body: {
      product_ids: productIds,
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopAvailability = (
  brandId: Id,
  webshopSessionId: Id,
  variantIds: Array<Id>
) =>
  api.post(`/webshop/${brandId}/availability`, {
    body: {
      variant_ids: variantIds,
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopDeliveryStatus = (
  brandId: Id,
  webshopSessionId: Id,
  variantIds: Array<Id>
) =>
  api.post(`/webshop/${brandId}/delivery-status`, {
    body: {
      variant_ids: variantIds,
      webshop_session_id: webshopSessionId,
    },
  })

// Landing

export const getWebshopLandingSettings = (brandId: Id) =>
  api.get(`/webshop/${brandId}/landing`)

export const getWebshopLandingTemplates = () =>
  api.get('/webshop/landing-templates')

// Public webshop

export const getPublicBrand = (id?: Id, options?: Object) =>
  api.get(`/brands/public`, {
    query: {
      brand_id: id,
      options: options || [],
    },
  })

export const registerNewAccount = (brandId: Id, account) =>
  api.post(`/webshop/${brandId}/register`, {
    body: {
      account,
    },
  })

// Payments

export const getWebshopSessionPayment = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/webshop/${brandId}/sessions/${webshopSessionId}/payment`)

export const getWebshopPayStatus = (
  brandId: Id,
  webshopSessionId: Id,
  token: string,
  webshop2: boolean
) =>
  api.get(
    `/webshop/pay/${brandId}/sessions/${webshopSessionId}/payments/${token}/status`,
    {
      query: {
        webshop_2: webshop2 ? 1 : 0,
      },
    }
  )

export const acceptWebshopPay = (
  brandId: Id,
  webshopSessionId: Id,
  token: string,
  data: Object
) =>
  api.post(
    `/webshop/pay/${brandId}/sessions/${webshopSessionId}/payments/${token}/accept`,
    {
      body: {
        data,
      },
    }
  )

export const acceptWebshopV2Pay = (
  brandId: Id,
  webshopSessionId: Id,
  token: string,
  data: Object
) =>
  api.post(
    `/webshop-v2/pay/${brandId}/sessions/${webshopSessionId}/payments/${token}/accept`,
    {
      body: {
        data,
      },
    }
  )

// Webshop product labels

export const getWebshopProductLabels = () => api.get('/webshop/product-labels')

export const createWebshopProductLabel = (data: Object) =>
  api.post('/webshop/product-labels', {
    body: {
      webshop_product_label: data,
    },
  })

export const updateWebshopProductLabel = (id: Id, data: Object) =>
  api.put(`/webshop/product-labels/${id}`, {
    body: {
      webshop_product_label: data,
    },
  })

export const deleteWebshopProductLabel = (id: Id) =>
  api.delete(`/webshop/product-labels/${id}`)

// SPLASH

export const getWebshopSplashForSession = (brandId: Id, webshopSessionId: Id) =>
  api.get(`/webshop/${brandId}/splash`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopSplash = options =>
  api.get('/webshop/splash', {
    query: options,
  })

export const createWebshopSplash = data =>
  api.post('/webshop/splash', {
    body: {
      splash: data,
    },
  })

export const updateWebshopSplash = (splashId, data) =>
  api.put(`/webshop/splash/${splashId}`, {
    body: {
      splash: data,
    },
  })

export const updateWebshopSplashBulk = data =>
  api.put(`/webshop/splash`, {
    body: {
      splash: data,
    },
  })

export const deleteWebshopSplash = splashId =>
  api.delete(`/webshop/splash/${splashId}`)

// LANDING

export const getWebshopLandingForSession = (
  brandId: Id,
  webshopSessionId: Id
) =>
  api.get(`/webshop/${brandId}/landing-v2`, {
    query: {
      webshop_session_id: webshopSessionId,
    },
  })

export const getWebshopLanding = options =>
  api.get('/webshop/landing-v2', {
    query: options,
  })

export const createWebshopLanding = data =>
  api.post('/webshop/landing-v2', {
    body: {
      landing: data,
    },
  })

export const updateWebshopLanding = (landingId, data) =>
  api.put(`/webshop/landing-v2/${landingId}`, {
    body: {
      landing: data,
    },
  })

export const updateWebshopLandingBulk = data =>
  api.put(`/webshop/landing-v2`, {
    body: {
      landing: data,
    },
  })

export const deleteWebshopLanding = landingId =>
  api.delete(`/webshop/landing-v2/${landingId}`)
