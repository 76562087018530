/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'

const DateControl = React.lazy<Object>(() =>
  import('../../../../../../infrastructure/components/DateControl')
)

import { renderDate } from '../../../../../shared'

import type { ColumnConfig } from '../../types'

const line_requested_delivery: (options: Options) => ColumnConfig = ({
  editable = false,
  // line_confirmed_delivery basically just uses this config with some other input props
  edit_property = 'requested_delivery',
  key = 'line_requested_delivery',
  label = 'Req. delivery',
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ [edit_property]: null }],
  editable: editable,
  key: key,
  label: label,
  rows: rows,
  split_by_values: true,
  value: ({ line }) => [{ [edit_property]: line ? line[edit_property] : null }],
  render: ({
    config,
    edit,
    onColumnValueChange,
    onEdit,
    product,
    variants,
    value,
  }) => {
    return (
      <EditableDateForm
        editable={editable}
        edit_property={edit_property}
        onColumnValueChange={onColumnValueChange}
        value={value}
      />
    )
  },
})

export default line_requested_delivery

const EditableDateForm = ({
  editable,
  edit_property,
  onColumnValueChange,
  value,
}) => {
  const currentValue = value[0] ? value[0][edit_property] : null

  const onChange = React.useCallback(
    newDate => onColumnValueChange({ [edit_property]: newDate }),
    [edit_property, onColumnValueChange]
  )

  if (!editable) {
    return currentValue ? <span>{renderDate(currentValue)}</span> : null
  }

  return (
    <StyledDateControl
      onChange={onChange}
      placeholder=""
      value={currentValue}
    />
  )
}

// We set table th padding=0 explicitly because there is some
// bootstrap rule that will make them too big
const StyledDateControl = styled(DateControl)`
  .rdtPicker {
    right: 0;
  }

  table th {
    padding: 0 !important;
  }

  input {
    height: 20px;
    min-height: 20px;
    line-height: 15px;
    padding: 6px 2px;
    border: 0;
    color: #576475;
    font-size: 11px;
    width: 90px;

    &:focus {
      padding: 6px 2px;
      border-color: inherit;
      outline: 0;
      box-shadow: none;
    }
  }
`
