/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import FormatNumber from '../../../../../../infrastructure/components/FormatNumber'

import type { ColumnConfig } from '../../types'

const unit_discount_percentage: (options: Options) => ColumnConfig = ({
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ discount_percentage: null }],
  key: 'unit_discount_percentage',
  label: 'Disc. %',
  rows: rows,
  right: true,
  split_by_values: true,
  value: ({ line }) => [
    { discount_percentage: line ? line.discount_percentage : null },
  ],
  render: ({ context, value }) => {
    if (!value[0]) {
      return null
    }

    return (
      <FormatNumber
        digits={2}
        value={value[0].discount_percentage}
        suffix="%"
      />
    )
  },
})

export default unit_discount_percentage
