/* @flow */

import api from '../api'

import type {
  ApiResponse,
  Attribute,
  Currency,
  CostPriceGroup,
  Filter,
  Id,
  Product,
  ProductCost,
  SalesPriceGroup,
  SortDirection,
  Variant,
} from '../types'
import type { VariantFormGroup } from './components/ProductProfile/VariantsForm'

export const getProduct = (productId: Id, options?: Object) =>
  api.get(`/products/${productId}`, {
    query: options,
  })

export const getProducts = (options?: Object) =>
  api.get(`/products`, {
    query: options,
  })

export const addMissingAttributes = (productId, params: Object) =>
  api.post(`/products/${productId}/add-missing-attributes`, {
    body: {
      data: params,
    },
  })

export const getAttributes = (): Promise<
  ApiResponse<{ attributes: Array<Attribute> }>
> => api.get('/attributes')

export const getAvailableProductsAttributes = () =>
  api.get('/products-elastic/attributes')

export const getProductsList = (options?: Object) =>
  api.post(`/products-elastic`, {
    body: options,
  })

export const getProductsAttributes = () => api.get('/products/list/attributes')

export const getProductsListV2 = (options?: Object) =>
  api.get('/products/list', {
    query: options,
  })

export const createProduct = (data: Object) =>
  api.post('/products', {
    body: {
      product: data,
    },
  })

export const updateProduct = (productId: Id, data: Object) =>
  api.put(`/products/${productId}`, {
    body: {
      product: data,
    },
  })

export const duplicateProduct = (product: Object) =>
  api.post(`/products/${product.id}/duplicate`, {
    body: {
      product: product,
    },
  })

export const deleteProduct = (productId: Id, data: Object) =>
  api.delete(`/products/${productId}`)

export const updateProductsBulk = (products: Array<Id>) =>
  api.put('/products', {
    body: {
      products: products,
    },
  })

export const deleteProductsBulk = (products: Array<{ id: Id }>) =>
  api.delete(`/products`, {
    body: {
      products,
    },
  })

export const getVariant = (variantId: Id, options?: Object) =>
  api.get(`/variants/${variantId}`, {
    query: options,
  })

export const getVariants = (options?: Object) =>
  api.get(`/variants`, {
    query: options,
  })

export const exportProducts = (
  columns: Array<string>,
  filterGroups: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  currencies?: Array<Currency>,
  onlyProductsMissingValue?: boolean,
  options
) =>
  api.post(`/products/export`, {
    body: {
      columns,
      filter_groups: filterGroups,
      currencies,
      only_products_missing_value: onlyProductsMissingValue,
      sort,
      options,
    },
  })

export const exportProductsToEdi = (
  connection: string,
  customerId,
  filters: Array<Filter>,
  limit?: number,
  internalCompanyId?: number
) =>
  api.post(`/edi/products/export`, {
    body: {
      connection,
      customer_id: customerId,
      filters,
      limit,
      internal_company_id: internalCompanyId,
    },
  })

export const sendProductsToEdi = (
  connection: string,
  customerId,
  filters: Array<Filter>,
  limit?: number,
  internalCompanyId?: number
) =>
  api.post(`/edi/products/send`, {
    body: {
      connection,
      customer_id: customerId,
      filters,
      limit,
      internal_company_id: internalCompanyId,
    },
  })

export const generateProductLabels = (labels: { [Id]: number }, options) =>
  api.post(`/products/generate-labels`, {
    body: {
      labels,
      ...options,
    },
  })

export const generateProductLabelsFromOrder = (orderId, orderType, options) =>
  api.post(`/products/generate-labels-from-order`, {
    body: {
      order_id: orderId,
      order_type: orderType,
      ...options,
    },
  })

export const getProductTableProducts = (
  brandId,
  customerId,
  ids: Array<Id>,
  variantIds: Array<Id> = [],
  options = {}
) =>
  api.post(`/products/${brandId}/product-table-products`, {
    body: {
      product_ids: ids,
      variant_ids: variantIds,
      customer_id: customerId,
      ...options,
    },
  })

export const getProductInsights = (productId, options?: Object) =>
  api.post(`/products/${productId}/insights`, {
    body: options,
  })

// Variants

export const createVariantsBulk = (variants: Array<$Shape<Variant>>) =>
  api.post('/products/variants', {
    body: {
      variants: variants,
    },
  })

export const updateVariantsBulk = (variants: Array<$Shape<Variant>>) =>
  api.put('/products/variants', {
    body: {
      variants: variants,
    },
  })

export const deleteVariantsBulk = (variants: Array<{ id: Id }>) =>
  api.delete(`/products/variants`, {
    body: {
      variants,
    },
  })

// EAN

export const generateEanNumbersForProduct = (
  productId: Id,
  onlyEmptyVariants: boolean = true,
  onlySpecificVariants: boolean | Array<Id> = false
) =>
  api.post(`/products/${productId}/variants/generate-ean`, {
    body: {
      only_empty_variants: onlyEmptyVariants,
      only_specific_variants: onlySpecificVariants,
    },
  })

// Product groups

export const getProductGroups = (options?: Object) =>
  api.get('/product-groups', {
    query: options,
  })

// Segmentation

export const getSegmentationRules = () => api.get('/products/segmentation')

export const getSegmentationRule = (id: Id) =>
  api.get(`/products/segmentation/${id}`)

export const createSegmentationRules = values =>
  api.post(`/products/segmentation`, {
    body: {
      segmentation_rules: values,
    },
  })

export const updateSegmentationRules = (id: Id, values) =>
  api.put(`/products/segmentation/${id}`, {
    body: {
      segmentation_rules: values,
    },
  })

export const deleteSegmentationRules = (id: Id) =>
  api.delete(`/products/segmentation/${id}`)

// Production

export const getProductionProduct = (productId: Id) =>
  api.get(`/production/products/${productId}`)

export const calculateProductCostPriceTotals = (
  productId: Id,
  costPriceGroups: Array<VariantFormGroup>
) =>
  api.post(`/production/products/${productId}/calculate-cost-price-totals`, {
    body: {
      cost_price_groups: costPriceGroups,
    },
  })

export const updateProductCosts = (
  productId: Id,
  productCosts: Array<ProductCost>
) =>
  api.put(`/production/products/${productId}/product-costs`, {
    body: {
      product_costs: productCosts,
    },
  })

export const manuallyUpdateLandedCosts = updates =>
  api.post('/production/costs/update-landed-cost', {
    body: {
      variants: updates,
    },
  })

// Cost prices

export const getCostPriceSets = () => api.get('/production/cost-prices/sets')

export const createCostPriceSets = (costPriceSet: Object) =>
  api.post('/production/cost-prices/sets', {
    body: {
      cost_price_set: costPriceSet,
    },
  })

export const updateCostPriceSet = (costPriceSetId: Id, costPriceSet: Object) =>
  api.put(`/production/cost-prices/sets/${costPriceSetId}`, {
    body: {
      cost_price_set: costPriceSet,
    },
  })

export const deleteCostPriceSet = (costPriceSetId: Id) =>
  api.delete(`/production/cost-prices/sets/${costPriceSetId}`)

// Tags

export const getTags = (options?: Object) =>
  api.get('/tags', {
    query: options,
  })

// Assortments

export const getAssortments = () => api.get('/assortments')

export const createAssortment = (data: Object) =>
  api.post('/assortments', {
    body: {
      assortment: data,
    },
  })

export const updateAssortment = (assortmentId: Id, data: Object) =>
  api.put(`/assortments/${assortmentId}`, {
    body: {
      assortment: data,
    },
  })

export const deleteAssortment = (assortmentId: Id) =>
  api.delete(`/assortments/${assortmentId}`)

// Attribute Groups

export const getAttributeGroups = () => api.get('/attribute-groups')

export const createAttributeGroup = (data: Object) =>
  api.post('/attribute-groups', {
    body: {
      attribute_group: data,
    },
  })

export const updateAttributeGroup = (attributeGroupId: Id, data: Object) =>
  api.put(`/attribute-groups/${attributeGroupId}`, {
    body: {
      attribute_group: data,
    },
  })

export const deleteAttributeGroup = (attributeGroupId: Id) =>
  api.delete(`/attribute-groups/${attributeGroupId}`)

// Drop segmentation

export const updateDropSegmentation = (
  productId: Id,
  attributesHideDrops: { [attributeValue: string]: Array<Id> }
) =>
  api.post(`/products/${productId}/drop-segmentation`, {
    body: {
      attributes_hide_drops: attributesHideDrops,
    },
  })

export const calculateOrdersDropDeliveriesSyncStatus = (productId: Id) =>
  api.get(
    `/products/${productId}/drop-segmentation/calculate-orders-drop-deliveries-sync-status`
  )

export const updateOrderDropDeliveries = (productId, updates) =>
  api.post(
    `/products/${productId}/drop-segmentation/update-order-drop-deliveries`,
    {
      body: {
        updates,
      },
    }
  )

// Launch events

export const getLaunchEvents = () => api.get('/launch-events')

export const getLaunchEvent = (launchEventId: Id) =>
  api.get(`/launch-events/${launchEventId}`)

export const createLaunchEvent = (data: Object) =>
  api.post('/launch-events', {
    body: {
      launch_event: data,
    },
  })

export const updateLaunchEvent = (launchEventId: Id, data: Object) =>
  api.put(`/launch-events/${launchEventId}`, {
    body: {
      launch_event: data,
    },
  })

export const deleteLaunchEvent = (launchEventId: Id) =>
  api.delete(`/launch-events/${launchEventId}`)

export const runLaunchEvent = (launchEventId: Id) =>
  api.post(`/launch-events/${launchEventId}/run`)

export const terminateLaunchEvent = (launchEventId: Id) =>
  api.post(`/launch-events/${launchEventId}/terminate`)

export const exportLaunchEvent = (launchEventId: Id) =>
  api.post(`/launch-events/${launchEventId}/export`)

export const importLaunchEventData = (launchEventId: Id, values) =>
  api.post(`/launch-events/${launchEventId}/import-data`, {
    body: values,
  })

// Image imports

export const getImageImports = options =>
  api.get('/products/image-imports', {
    query: options,
  })

export const getImageImportView = (id: Id) =>
  api.get(`/products/image-imports/${id}`)

export const createImageImport = data =>
  api.post('/products/image-imports', {
    body: {
      image_import: data,
    },
  })

export const completeImageImport = id =>
  api.post(`/products/image-imports/${id}/complete`)

export const matchImageImportToProducts = (id, options, files) =>
  api.post(`/products/image-imports/${id}/match-to-products`, {
    body: {
      options,
      files,
    },
  })

export const deleteImageImport = id =>
  api.delete(`/products/image-imports/${id}`)
