/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import CloudinaryResource from '../../../../infrastructure/components/CloudinaryResource'
import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { FakeLinkStyle } from '../../../shared'

import { ProductTableContext } from './shared'
import { SessionContext } from '../../../shared'

const SummaryProductTable = ({
  dataContext,
  linesByProductId,
  products,
  summaryProductTableState,
  summaryProductTableActions,
}) => {
  const { brand } = React.useContext(SessionContext)
  const { settings: productTableSettings, updateLines } =
    React.useContext(ProductTableContext)

  const options = summaryProductTableState.options
  const quantityColumns = options.quantityColumns

  const { productRows, verticalAttributes } = React.useMemo(() => {
    const productRows = []
    let verticalAttributes = []

    for (let product of products) {
      const linesOfProduct = linesByProductId[product.id] || []

      let linesByVerticalAttributeValue = {
        '': {
          attributes_filter: {},
          lines: linesOfProduct,
          vertical_attributes_label: '',
        },
      }
      if (
        brand.settings.horizontal_attribute &&
        product.attributes.length > 0
      ) {
        linesByVerticalAttributeValue = {}

        // we dont want too many vertical attributes, so we just take the first
        const verticalAttributeOfProduct = product.attributes[0]

        if (!verticalAttributes.includes(verticalAttributeOfProduct)) {
          verticalAttributes.push(verticalAttributeOfProduct)
        }

        const linesWithMainQuantity = linesOfProduct.filter(
          line => line[options.mainQuantityColumn] > 0
        )

        for (let line of linesWithMainQuantity) {
          if (line.delete) {
            continue
          }

          const variant = product.variants.find(
            variant => variant.id == line.variant_id
          )

          if (!variant) {
            return null
          }

          const attributesFilter = {
            [verticalAttributeOfProduct]:
              variant.attributes[verticalAttributeOfProduct],
          }

          const verticalAttributeValue = []
          if (variant.attributes_codes[verticalAttributeOfProduct]) {
            verticalAttributeValue.push(
              variant.attributes_codes[verticalAttributeOfProduct]
            )
          }

          verticalAttributeValue.push(
            variant.attributes[verticalAttributeOfProduct]
          )

          const verticalAttributeLabel = verticalAttributeValue.join(' ')

          if (!linesByVerticalAttributeValue[verticalAttributeLabel]) {
            linesByVerticalAttributeValue[verticalAttributeLabel] = {
              attributes_filter: attributesFilter,
              lines: [],
              vertical_attributes_label: verticalAttributeLabel,
            }
          }

          linesByVerticalAttributeValue[verticalAttributeLabel].lines.push(line)
        }
      }

      let verticalAttributeRows = Object.values(linesByVerticalAttributeValue)
      if (verticalAttributeRows.length == 0) {
        verticalAttributeRows = [
          {
            attributes_filter: {},
            lines: [],
            vertical_attributes_label: '',
          },
        ]
      }

      const variantRows = []
      for (let verticalAttributeEntry of verticalAttributeRows) {
        const {
          attributes_filter,
          lines: linesOfVerticalAttribute,
          vertical_attributes_label: verticalAttributesLabel,
        } = verticalAttributeEntry

        let mainQuantity = 0
        let totalPrice = 0
        const quantityColumnsOfVerticalAttribute = options.quantityColumns.map(
          column => ({
            key: column.property,
            property: column.property,
            label: column.label,
            value: 0,
          })
        )

        let image = verticalAttributesLabel === '' ? product.image : null
        for (let line of linesOfVerticalAttribute) {
          if (line[options.mainQuantityColumn] === 0) {
            continue
          }

          mainQuantity += line[options.mainQuantityColumn]

          const variant = product.variants.find(
            variant => variant.id === line.variant_id
          )

          if (variant.picture && !image) {
            image = variant.picture
          }

          let assortmentQuantity = 1
          if (variant.assortment_id) {
            assortmentQuantity = variant.assortment_quantity
          }

          totalPrice +=
            line[options.mainQuantityColumn] *
            (line[options.unitPriceColumn] || 0)

          for (let column of quantityColumnsOfVerticalAttribute) {
            column.value += (line[column.property] || 0) * assortmentQuantity
          }
        }

        variantRows.push({
          attributes_filter,
          image: image,
          lines: linesOfVerticalAttribute,
          main_quantity: mainQuantity,
          quantity_columns: quantityColumnsOfVerticalAttribute,
          unit_price: totalPrice / mainQuantity,
          total_price: totalPrice,
          vertical_attribute: verticalAttributesLabel,
        })
      }

      const sorted = sortBy(Object.values(variantRows), 'vertical_attribute')

      const productRow = {
        product,
        variant_rows: sorted,
        lines: linesOfProduct,
      }

      productRows.push(productRow)
    }

    const sortedProductRows = sortBy(productRows, row => {
      if (productTableSettings.sort === 'item_number') {
        return row.product.item_number
      }

      return row.product.name
    })

    return {
      productRows: sortedProductRows,
      verticalAttributes: verticalAttributes.join('/'),
    }
  }, [
    brand.settings.horizontal_attribute,
    linesByProductId,
    products,
    productTableSettings.sort,
    options.quantityColumns,
    options.mainQuantityColumn,
    options.unitPriceColumn,
    options.totalPriceColumn,
  ])

  const onDeleteProduct = React.useCallback(
    variantRow => {
      updateLines({
        type: 'delete',
        lines: variantRow.lines,
      })
    },
    [updateLines]
  )

  return (
    <div>
      <SummaryProductTableActionsContainer>
        <button
          type="button"
          className="btn btn-sm btn-white"
          onClick={() => summaryProductTableActions.show_all_lines()}
        >
          Show all lines
        </button>
      </SummaryProductTableActionsContainer>

      <table className="table table-bordered table-condensed-xs">
        <thead>
          <tr>
            <th />
            <th>Item number</th>
            <th>Name</th>
            {verticalAttributes && <th>{verticalAttributes} </th>}
            {quantityColumns.map(column => {
              return (
                <th key={column.property} className="text-right">
                  {column.label}
                </th>
              )
            })}
            <th className="text-right">Unit price</th>
            <th className="text-right">Total price</th>
            <th className="listview-action" />
          </tr>
        </thead>
        <tbody>
          {productRows.map(productRow => {
            return (
              <>
                {productRow.variant_rows.map(variantRow => {
                  return (
                    <tr key={variantRow.vertical_attribute}>
                      <td>
                        <CloudinaryResource
                          id={variantRow.image}
                          presets="table"
                          fallback="product_table"
                          zoomOnHover
                        />
                      </td>
                      <td>
                        <FakeLinkStyle
                          onClick={() =>
                            summaryProductTableActions.toggle_product(
                              productRow
                            )
                          }
                        >
                          {productRow.product.item_number}
                        </FakeLinkStyle>
                      </td>
                      <td>
                        <FakeLinkStyle
                          onClick={() =>
                            summaryProductTableActions.toggle_product(
                              productRow
                            )
                          }
                        >
                          {productRow.product.name}
                        </FakeLinkStyle>
                      </td>
                      {verticalAttributes && (
                        <td>
                          <FakeLinkStyle
                            onClick={() =>
                              summaryProductTableActions.toggle_product(
                                productRow,
                                variantRow.attributes_filter
                              )
                            }
                          >
                            {variantRow.vertical_attribute}
                          </FakeLinkStyle>
                        </td>
                      )}
                      {variantRow.quantity_columns.map(column => {
                        return (
                          <td key={column.key} className="text-right">
                            {column.property === options.mainQuantityColumn && (
                              <strong>{column.value}</strong>
                            )}
                            {column.property !== options.mainQuantityColumn &&
                              column.value}
                          </td>
                        )
                      })}
                      <td className="text-right">
                        <FormatCurrency currency={dataContext.currency}>
                          {variantRow.unit_price}
                        </FormatCurrency>
                      </td>
                      <td className="text-right">
                        <FormatCurrency currency={dataContext.currency}>
                          {variantRow.total_price}
                        </FormatCurrency>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-white btn-xs"
                          onClick={() => onDeleteProduct(variantRow)}
                        >
                          <span className="glyphicon glyphicon-remove" />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default SummaryProductTable

const SummaryProductTableActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`
