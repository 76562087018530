/* @flow */

import api from '../api'

import type {
  ApiResponse,
  BrunoRequestOptions,
  DocumentExchange,
  Id,
  ShopifyShop,
  WoocommerceShop,
} from '../types'

// Apps

export const installApp = (app: string, settings: Object, secrets?: Object) =>
  api.post('/apps', {
    body: {
      app: {
        app,
        settings,
        secrets,
      },
    },
  })

export const updateAppSettings = (id: Id, settings: Object, secrets?: Object) =>
  api.put(`/apps/${id}`, {
    body: {
      app: { settings, secrets },
    },
  })

export const uninstallApp = (id: Id) => api.delete(`/apps/${id}`)

export const getDocumentExchangeDocuments = (
  options?: BrunoRequestOptions
): Promise<ApiResponse<{ documents: Array<DocumentExchange> }>> =>
  api.get('/document-exchange', {
    query: options,
  })

export const getDocumentExchangeTableParameters = () =>
  api.get('/document-exchange/table-parameters')

export const downloadDocumentExchangeFile = (
  id: Id
): Promise<ApiResponse<{ name: string, code: string }>> =>
  api.post(`/document-exchange/download-file/${id}`)

export const replaceDocumentExchangeFile = (fileId: Id, newFile: string) =>
  api.post(`/document-exchange/files/${fileId}/replace`, {
    body: {
      file: newFile,
    },
  })

export const queueDocumentExchangeTry = (id: Id) =>
  api.post(`/document-exchange/${id}/queue-try`)

export const abandonDocumentExchange = (id: Id) =>
  api.post(`/document-exchange/${id}/abandon`)

export const reviveDocumentExchange = (id: Id) =>
  api.post(`/document-exchange/${id}/revive`)

// DSV

export const sendMasterDataDsv = () => api.post('/apps/dsv/push-products')

export const downloadDsvPublicKey = () =>
  api.post('/apps/dsv/download-public-key')

// Rhenus
export const sendMasterDataRhenus = () => api.post('/apps/rhenus/push-products')

export const downloadRhenusPublicKey = () =>
  api.post('/apps/rhenus/download-public-key')

// PrimeCargo

export const sendMasterDataPrimeCargo = () =>
  api.post('/apps/primecargo/push-products')

export const sendMasterDataSystemtransport = () =>
  api.post('/apps/systemtransport/push-products')

export const sendMasterDataCoolrunner = () =>
  api.post('/apps/coolrunner/push-products')

export const sendMasterDataTmc = () => api.post('/apps/tmc/push-products')

// MidtFactoring

export const repushInvoicesToMidtFactoring = (
  invoiceNumbers,
  creditNoteNumbers
) =>
  api.post('/apps/midtfactoring/repush-invoiceables', {
    body: {
      invoice_numbers: invoiceNumbers,
      credit_note_numbers: creditNoteNumbers,
    },
  })

// Shopify

export const getShopifyShops = (
  options
): Promise<ApiResponse<{ shops: Array<ShopifyShop> }>> =>
  api.get('/shopify/shops', {
    query: options,
  })

export const updateShopifyShop = (id: Id, data: $Shape<ShopifyShop>) =>
  api.put(`/shopify/shops/${id}`, {
    body: {
      shop: data,
    },
  })

export const getShopifyInventoryLocations = () =>
  api.get('/shopify/shops/locations')

export const installShopifyFulfillmentWebhook = shopId =>
  api.post(`/shopify/shops/${shopId}/install-fulfillment-webhook`)

export const installShopifyOrderWebhook = shopId =>
  api.post(`/shopify/shops/${shopId}/install-order-webhook`)

export const installShopifyRefundWebhook = shopId =>
  api.post(`/shopify/shops/${shopId}/install-refund-webhook`)

export const activateShopifyConsignmentLocation =
  shopifyConsignmentLocationId =>
    api.post(
      `/shopify/shops/locations/${shopifyConsignmentLocationId}/activate-consignment-location`
    )

export const deactivateShopifyConsignmentLocation =
  shopifyConsignmentLocationId =>
    api.post(
      `/shopify/shops/locations/${shopifyConsignmentLocationId}/deactivate-consignment-location`
    )

export const queueShopifyImageImport = shopifyShopId =>
  api.post(`/shopify/shops/${shopifyShopId}/import-images`)

export const disableCreditNoteShopifyRefund = (
  creditNoteId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/shopify/credit-notes/${creditNoteId}/disable-shopify-refund`)

export const exportShopifyProductsToExcel = (
  shopIds: Array<Id>,
  stripDescriptionTags
) =>
  api.post(`/shopify/export-products`, {
    body: {
      shop_ids: shopIds,
      strip_description_tags: stripDescriptionTags,
    },
  })

export const completeStoreInstallationFromAppStore = (settings, query) => {
  return api.post('/shopify/complete-store-installation', {
    body: {
      settings,
      query,
    },
  })
}

export const queueWoocommerceImageImport = woocommerceShopId =>
  api.post(`/woocommerce/shops/${woocommerceShopId}/import-images`)

export const exportWoocommerceMetaFields = shopIds =>
  api.post('/woocommerce/export-meta-fields', {
    body: {
      shop_ids: shopIds,
    },
  })

export const exportWoocommerceProductsToExcel = (shopIds: Array<Id>, options) =>
  api.post(`/woocommerce/export-products`, {
    body: {
      shop_ids: shopIds,
      ...options,
    },
  })

export const resyncShopifyOrder = orderId =>
  api.post(`/shopify/orders/${orderId}/resync`)

export const skipShopifyOrderSync = (
  orderIds: Array<Id>
): Promise<ApiResponse<void>> =>
  api.post(`/shopify/orders/skip-order-sync`, {
    body: {
      order_ids: orderIds,
    },
  })

export const updateShopifyOrderPivotAndSync = (
  pivotId,
  updates
): Promise<ApiResponse<void>> =>
  api.put(`/shopify/orders/update-pivot-and-sync`, {
    body: {
      pivot_id: pivotId,
      updates,
    },
  })

export const installCustomShopifyApp = (currency, url, customKey) =>
  api.post('/shopify/install-custom-app', {
    body: {
      currency,
      url,
      custom_key: customKey,
    },
  })

export const convertToCustomShopifyApp = (shopifyShopId, accessToken) =>
  api.post('/shopify/convert-to-custom-app', {
    body: {
      shopify_shop_id: shopifyShopId,
      access_token: accessToken,
    },
  })

export const getShopifyMarkets = shopifyShopId =>
  api.get(`/shopify/shops/${shopifyShopId}/markets`)

// Woocommerce

export const createWoocommerceDataCheckReport = (shopId: Id) =>
  api.post(`/woocommerce/${shopId}/data-check`)

export const createWoocommerceShop = (data: $Shape<WoocommerceShop>, secrets) =>
  api.post('/woocommerce/shops', {
    body: {
      secrets,
      shop: data,
    },
  })

export const updateWoocommerceShop = (id: Id, data: $Shape<WoocommerceShop>) =>
  api.put(`/woocommerce/shops/${id}`, {
    body: {
      shop: data,
    },
  })

export const deleteWoocommerceShop = (id: Id, data: $Shape<WoocommerceShop>) =>
  api.delete(`/woocommerce/shops/${id}`, {})

export const getWoocommerceShops = () => api.get('/woocommerce/shops')

export const resyncWoocommerceOrder = (
  orderId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/woocommerce/orders/${orderId}/resync`)

export const skipWoocommerceOrderSync = (
  orderIds: Array<Id>
): Promise<ApiResponse<void>> =>
  api.post(`/woocommerce/orders/skip-order-sync`, {
    body: {
      order_ids: orderIds,
    },
  })

// eaccounting

export const verifyEaccountingAuthentication = (code, redirect_uri) =>
  api.post('/eaccounting/verify-authentication', {
    body: {
      code,
      redirect_uri,
    },
  })

// DNB

export const pushInvoiceablesToDnb = () => api.post('/dnb/push-invoiceables')

// Shipmondo

export const getShipmondoPrinterClients = () =>
  api.get('/shipmondo/printer-clients')

// Webshipper

export const getWebshipperPrinterClients = () =>
  api.get('/webshipper/printer-clients')

export const getAvailableWebshipperShippingRates = orderShipmentId =>
  api.post(`/webshipper/shipments/${orderShipmentId}/get-available-rates`)

export const pushShipmentWebshipper = shipmentId =>
  api.post(`/webshipper/shipments/${shipmentId}/push`)

export const pushShipmentDeliveryNoteWebshipper = shipmentDeliveryNoteId =>
  api.post(`/webshipper/shipment-delivery-notes/${shipmentDeliveryNoteId}/push`)

// Riverty

export const getRivertyBatchData = (batchId: Id) =>
  api.get(`/riverty/transfer-document-data/${batchId}`)

export const getRivertyBatches = () => api.get(`/riverty/batches`)

// Exact online

export const verifyExactAuthentication = (query: Object) =>
  api.post(`/apps/exact/verify-authentication`, {
    body: query,
  })

// Ongoing WMS

export const pushAllProductsOngoing = () =>
  api.post('/apps/ongoing/push-products')

export const getOngoingWarehouses = () => api.get('/apps/ongoing/warehouses')

// ERP

export const getErpApps = (axiosOptions = {}) =>
  api.get(`/erp/erp-apps`, {
    ...axiosOptions,
  })

export const getErpAccounts = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/accounts`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpB2cPaymentAcquirers = (
  inputApp = null,
  internalCompanyId = null
) =>
  api.get(`/erp/b2c-payment-acquirers`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpB2cPaymentGateways = (
  inputApp = null,
  internalCompanyId = null
) =>
  api.get(`/erp/b2c-payment-gateways`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpBankAccounts = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/bank-accounts`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpCashBooks = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/cash-books`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpCostCenters = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/cost-centers`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpCustomerPaymentJournals = (
  inputApp = null,
  internalCompanyId = null
) =>
  api.get(`/erp/customer-payment-journals`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpCompanies = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/companies`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpDimensions = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/dimensions`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpCustomerGroups = (
  inputApp = null,
  internalCompanyId = null
) =>
  api.get(`/erp/customer-groups`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpLayouts = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/layouts`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpVatCodes = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/vat-codes`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpVatZones = (inputApp = null, internalCompanyId = null) =>
  api.get(`/erp/vat-zones`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const getErpVoucherSeries = (
  inputApp = null,
  internalCompanyId = null
) =>
  api.get(`/erp/voucher-series`, {
    query: {
      app: inputApp,
      internal_company_id: internalCompanyId,
    },
  })

export const createErpAccountingDiffReport = (
  from,
  to,
  accounts,
  includeFromJournals,
  inputApp = null,
  internalCompanyId = null
) =>
  api.post(`/erp/create-accounting-diff-report`, {
    body: {
      from,
      to,
      accounts,
      app: inputApp,
      internal_company_id: internalCompanyId,
      include_from_journals: includeFromJournals,
    },
  })

export const createErpRevenueDiffReport = (
  from,
  to,
  accounts,
  inputApp = null,
  internalCompanyId = null,
  orderTypes = [],
  regionFilter = [],
  countryFilter = [],
  includeFromJournals = []
) =>
  api.post(`/erp/create-revenue-diff-report`, {
    body: {
      from,
      to,
      accounts,
      app: inputApp,
      internal_company_id: internalCompanyId,
      order_types: orderTypes,
      region_filter: regionFilter,
      country_filter: countryFilter,
      includeFromJournals: includeFromJournals,
    },
  })

export const exportErpCustomersToExcel = (
  customerGroups = [],
  inputApp = null,
  internalCompanyId = null,
  includeInactive = false,
  extraOptions = {}
) =>
  api.post(`/erp/export-customers-excel`, {
    body: {
      customer_groups: customerGroups,
      app: inputApp,
      internal_company_id: internalCompanyId,
      include_inactive: includeInactive,
      extra_options: extraOptions,
    },
  })

export const skipInvoiceSync = invoiceIds =>
  api.post(`/erp/skip-invoice-sync`, {
    body: {
      invoice_ids: invoiceIds,
    },
  })

export const resyncInvoice = (app, ids): Promise<ApiResponse<void>> =>
  api.post(`/erp/resync-invoice`, {
    body: {
      app,
      ids,
    },
  })

export const fixMissingB2cGatewayAccount = (
  type,
  gatewayOrAcquirer,
  byCurrency,
  account,
  b2cPayments
) =>
  api.post(`/accounting/fix-missing-b2c-gateway-account`, {
    body: {
      account,
      gateway_or_acquirer: gatewayOrAcquirer,
      by_currency: byCurrency,
      type,
      b2c_payments: b2cPayments,
    },
  })

// Picqer WMS

export const pushProductionOrderDeliveryNotePicqer =
  productionOrderDeliveryNoteId =>
    api.post(
      `/apps/picqer/production-orders/${productionOrderDeliveryNoteId}/push`
    )

export const getPicqerShippingProviders = () =>
  api.get(`/apps/picqer/shipping-providers`)

export const pushAllProductsPicqer = () =>
  api.post('/apps/picqer/push-products')

// Sendcloud

export const getSendcloudShippingMethods = () =>
  api.get(`/apps/sendcloud/shipping-methods`)

// HLT

export const sendInventoryStatementHlt = () =>
  api.post('/apps/hlt/push-inventory')

export const sendMasterDataHlt = () => api.post('/apps/hlt/push-products')

// EDI

export const mockMagasinSharedImportFile = remotePath =>
  api.post('/edi/magasin/mock-shared-import', {
    body: {
      path: remotePath,
    },
  })

// Fortnox

export const verifyFortnoxAuthentication = (query: Object) =>
  api.post(`/apps/fortnox/verify-authentication`, {
    body: query,
  })

// Economic

export const resyncEconomicB2cPaymentCreditNote = (
  creditNoteId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/economic/credit-notes/${creditNoteId}/resync-b2c-payment`)

export const resyncEconomicB2cPaymentInvoice = (
  invoiceId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/economic/invoices/${invoiceId}/resync-b2c-payment`)

export const skipEconomicB2cPaymentCreditNoteSync = (
  creditNoteId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/economic/credit-notes/${creditNoteId}/skip-b2c-payment-sync`)

export const skipEconomicB2cPaymentInvoiceSync = (
  invoiceId: Id
): Promise<ApiResponse<void>> =>
  api.post(`/economic/invoices/${invoiceId}/skip-b2c-payment-sync`)

// Xero

export const verifyXeroAuthentication = (code, redirect_uri) =>
  api.post('/apps/xero/verify-authentication', {
    body: {
      code,
      redirect_uri,
    },
  })

// Quickbook

export const verifyQuickbooksAuthentication = (code, redirect_uri, realmId) =>
  api.post('/apps/quickbooks/verify-authentication', {
    body: {
      code,
      redirect_uri,
      realm_id: realmId,
    },
  })

// Data exports

export const getDataExportTargets = (options?: Object) =>
  api.get(`/apps/data-exports/targets`, {
    query: options,
  })

export const createDataExportTarget = data =>
  api.post(`/apps/data-exports/targets`, {
    body: {
      data_export_target: data,
    },
  })

export const updateDataExportTarget = (id, data) =>
  api.post(`/apps/data-exports/targets/${id}`, {
    body: {
      data_export_target: data,
    },
  })

export const queueDataExportTargetReset = id =>
  api.post(`/apps/data-exports/targets/${id}/queue-reset`)

export const queueDataExportTargetPreparation = (id, syncImmediately) =>
  api.post(`/apps/data-exports/targets/${id}/queue-preparation`, {
    body: {
      sync_immediately: syncImmediately,
    },
  })

export const queueDataExportTargetSync = id =>
  api.post(`/apps/data-exports/targets/${id}/queue-sync`)

export const getDataExportSchemes = () =>
  api.get('/apps/data-exports/targets/schemes')

// Klaviyo

export const getKlaviyoLists = () => api.get(`/apps/klaviyo/lists`)

export const queueKlaviyoCustomerEmailPush = () =>
  api.post('/apps/klaviyo/queue-customer-email-push')

export const queueKlaviyoProductPush = () =>
  api.post('/apps/klaviyo/queue-product-push')

export const queueKlaviyoOrderHistoryPush = () =>
  api.post('/apps/klaviyo/queue-order-history-push')

// Faire

export const getFaireTaxonomies = (options?: Object) =>
  api.get('/apps/faire/taxonomies', {
    query: options,
  })

// Zalando

export const getZalandoSalesChannels = (options?: Object) =>
  api.get('/apps/zalando/sales-channels', {
    query: options,
  })

export const getZalandoLocations = (options?: Object) =>
  api.get('/apps/zalando/locations', {
    query: options,
  })

export const getZalandoOutlines = (options?: Object) =>
  api.get('/apps/zalando/outlines', {
    query: options,
  })

export const getZalandoOutlineSchema = (outline, loadAttributes) =>
  api.post('/apps/zalando/outline-schema', {
    body: {
      outline,
      load_attributes: loadAttributes,
    },
  })

export const getZalandoAttributes = attributes =>
  api.post('/apps/zalando/attributes', {
    body: {
      attributes,
    },
  })

// Business Central

export const getBusinessCentralCompanies = () =>
  api.get('/apps/business-central/companies')

export const getBusinessCentralCustomerPostingGroups = () =>
  api.get('/apps/business-central/customer-posting-groups')

export const getBusinessCentralVendorPostingGroups = () =>
  api.get('/apps/business-central/vendor-posting-groups')

export const getBusinessCentralGenBusinessPostingGroups = () =>
  api.get('/apps/business-central/gen-business-posting-groups')

export const getBusinessCentralVatBusinessPostingGroups = () =>
  api.get('/apps/business-central/vat-business-posting-groups')

// Zalando

export const generateZalandoDeliveryNotePdf = shipmentId =>
  api.post(`/apps/zalando/shipments/${shipmentId}/delivery-note-pdf`)

export const generateZalandoPalletLabelsPdf = shipmentId =>
  api.post(`/apps/zalando/shipments/${shipmentId}/pallet-labels-pdf`)

export const generateZalandoParcelLabelsPdf = shipmentId =>
  api.post(`/apps/zalando/shipments/${shipmentId}/parcel-labels-pdf`)

// Delogue

export const triggerDelogueProductSync = itemNumbers =>
  api.post(`/apps/delogue/trigger-product-sync`, {
    body: {
      item_numbers: itemNumbers,
    },
  })

// Quickpay

export const getQuickpayAccounts = (axiosOptions, options?: Object) =>
  api.get(`/apps/quickpay/accounts`, {
    query: options,
    ...axiosOptions,
  })

export const createQuickpayAccount = data =>
  api.post(`/apps/quickpay/accounts`, {
    body: {
      account: data,
    },
  })

export const updateQuickpayAccount = (accountId, data) =>
  api.put(`/apps/quickpay/accounts/${accountId}`, {
    body: {
      account: data,
    },
  })

export const deleteQuickpayAccount = accountId =>
  api.delete(`/apps/quickpay/accounts/${accountId}`)

// Pensopay

export const getPensopayAccounts = (axiosOptions, options?: Object) =>
  api.get(`/apps/pensopay/accounts`, {
    query: options,
    ...axiosOptions,
  })

export const createPensopayAccount = data =>
  api.post(`/apps/pensopay/accounts`, {
    body: {
      account: data,
    },
  })

export const updatePensopayAccount = (accountId, data) =>
  api.put(`/apps/pensopay/accounts/${accountId}`, {
    body: {
      account: data,
    },
  })

export const deletePensopayAccount = accountId =>
  api.delete(`/apps/pensopay/accounts/${accountId}`)
