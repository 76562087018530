/* @flow */

import api from '../api'

import type { ApiResponse, Id } from '../types'

export const createNews = (data: Object) =>
  api.post('/admin/news', {
    body: {
      news: data,
    },
  })

export const updateNews = (newsId: Id, data: Object) =>
  api.put(`/admin/news/${newsId}`, {
    body: {
      news: data,
    },
  })

export const getFreemiumCustomers = (data: Object) =>
  api.get('/admin/freemium-customers', {
    query: data,
  })

export const getPayingCustomers = (data: Object) =>
  api.get('/admin/paying-customers-2', {
    query: data,
  })

export const getPayingCustomer = (payingCustomerId: Id, query?: Object) =>
  api.get(`/admin/paying-customers/${payingCustomerId}`, {
    query,
  })

export const updatePayingCustomer = (payingCustomerId: Id, data: Object) =>
  api.put(`/admin/paying-customers/${payingCustomerId}`, {
    body: { data: data },
  })

export const addPayingCustomerApi = (data: Object) =>
  api.post(`/admin/paying-customers`, {
    body: { data: data },
  })

export const getPayingCustomersAttributes = () =>
  api.get('/admin/paying-customers-2/attributes')

export const connectInvoiceToShipment = (
  shipmentDeliveryNoteId: Id,
  invoiceId: Id
) =>
  api.post('/admin/connect-invoice-to-shipment', {
    body: {
      shipment_delivery_note_id: shipmentDeliveryNoteId,
      invoice_id: invoiceId,
    },
  })

export const convertUserType = (entityId: Id, email: String, newType: String) =>
  api.post('/admin/convert-user-type', {
    body: {
      entity_id: entityId,
      email: email,
      new_type: newType,
    },
  })

export const synchronizeAgentsToOldData = (
  entityId: Id,
  email: String,
  orderId: String,
  customerId: String,
  since: String
) =>
  api.post('/admin/synchronize-agents-old-data', {
    body: {
      entity_id: entityId,
      email: email,
      order_id: orderId,
      customer_id: customerId,
      since: since,
    },
  })

export const deleteConsignmentLocation = (
  entityId: Id,
  customerId: String,
  customerName: String
) =>
  api.post('/admin/delete-consignment-location', {
    body: {
      entity_id: entityId,
      customer_id: customerId,
      customer_name: customerName,
    },
  })

export const clearImportCache = (entityId: Id) =>
  api.post('/admin/clear-import-cache', {
    body: {
      entity_id: entityId,
    },
  })

export const documentsUpdateCommand = (
  documentable: String,
  data_type: String
) =>
  api.post('/admin/documents-update-command', {
    body: {
      documentable: documentable,
      data_type: data_type,
    },
  })

export const removeDocumentFooter = (documentable: String, data_type: String) =>
  api.post('/admin/remove-document-footer', {
    body: {
      documentable: documentable,
      data_type: data_type,
    },
  })

export const putItemsBackOnOrder = (creditNoteId: Id) =>
  api.post('/admin/put-items-back-on-order', {
    body: {
      credit_note_id: creditNoteId,
    },
  })

export const getPlans = () => api.get(`/admin/plans`)

export const getUserLogins = (entityId: Id, from, to) =>
  api.post(`/admin/user-logins/${entityId}`, {
    body: {
      from,
      to,
    },
  })

export const exportRevenueToExcel = (startDate, endDate, payingCustomerIds) =>
  api.post('/admin/paying-customers/revenue-export', {
    body: {
      start_date: startDate,
      end_date: endDate,
      paying_customer_ids: payingCustomerIds,
    },
  })

export const exportRetailersReport = () =>
  api.post('/admin/paying-customers/retailer-export', {
    body: {},
  })

export const exportPayingCustomersYearlyReport = () =>
  api.post('/admin/paying-customers/yearly-report-export', {
    body: {},
  })

export const setupPayingCustomerFromQuestionnaire = (id: Id) =>
  api.post(`/admin/paying-customers/${id}/setup-brand`)

export const getFeatureFlagAdoption = (featureFlags, webshopV1Brands) =>
  api.post('/admin/feature-flag-adoption', {
    body: {
      feature_flags: featureFlags,
      webshop_v1_brands: webshopV1Brands,
    },
  })

export const getAgentList = options =>
  api.post('/admin/agents', {
    body: options,
  })

export const getImportJobTypes = () => api.get(`/admin/import-jobs/types`)

export const getImportJobs = (page, limit, entityId, type, jobIds) =>
  api.post(`/admin/import-jobs`, {
    body: {
      page,
      limit,
      entity_id: entityId,
      type,
      job_ids: jobIds,
    },
  })

export const getImportJobResult = id =>
  api.get(`/admin/import-jobs/${id}/result`)

export const exportImportJobCsv = id =>
  api.post(`/admin/import-jobs/${id}/export-csv`)

export const exportImportJobExcel = id =>
  api.post(`/admin/import-jobs/${id}/export-excel`)

export const developerTest = () => api.post(`/admin/developer-test`)

export const getSettingChangeTypes = () =>
  api.get(`/admin/setting-changes/types`)

export const getSettingChanges = options =>
  api.get(`/admin/setting-changes`, {
    query: options,
  })

export const fixInvoiceCreditNoteDates = (entityId, comment, files) =>
  api.post('/admin/fix-invoice-credit-note-dates', {
    body: {
      entity_id: entityId,
      comment,
      files,
    },
  })

export const purgeAllUnusedProductAttibutesApiCall = (entityId: Id) =>
  api.post('/admin/purge-all-unused-product-attributes', {
    body: {
      entity_id: entityId,
    },
  })

export const syncUpdatedShopifyOrders = (entityId, orderNumbers) =>
  api.post('/admin/shopify/sync-updated-orders', {
    body: {
      entity_id: entityId,
      order_numbers: orderNumbers,
    },
  })
