/* @flow */

import React from 'react'
import styled from 'styled-components'

import MatrixVariantsSection from './MatrixVariantsSection'

import type { Section } from '../../types'

const MatrixRowsRenderer = ({
  generatedSections,
  ...rest
}: {
  generatedSections: Array<Section>,
  ...
}) => {
  return (
    <>
      {generatedSections.map(
        ({
          allVariantsByHorizontalAttribute,
          data,
          rowGroups,
          totalRowCount,
          key,
          hasNoos,
          verticalAttributes,
          verticalAttributesCodes,
          verticalAttributesObject,
          variantsByHorizontalAttribute,
          variantsById,
        }) => {
          return (
            <SectionTbody key={key}>
              <MatrixVariantsSection
                allVariantsByHorizontalAttribute={
                  allVariantsByHorizontalAttribute
                }
                data={data}
                hasNoos={hasNoos}
                rowGroups={rowGroups}
                totalRowCount={totalRowCount}
                verticalAttributes={verticalAttributes}
                verticalAttributesCodes={verticalAttributesCodes}
                verticalAttributesValues={verticalAttributesObject}
                verticalAttributeKey={key}
                variantsByHorizontalAttribute={variantsByHorizontalAttribute}
                variantsById={variantsById}
                {...rest}
              />
            </SectionTbody>
          )
        }
      )}
    </>
  )
}

export default MatrixRowsRenderer

const SectionTbody = styled.tbody`
  page-break-inside: avoid;
`
