/* @flow */

import unit_price from './unit_price'

const unit_price_after_discount = (options = {}) =>
  unit_price({
    // we cannot override key as `depends_on` in total_price uses unit_price
    //key: 'unit_price_after_discount',
    priceProperty: 'net_price',
    showDiscount: false,
    label: 'Net unit price',
    ...options,
  })

export default unit_price_after_discount
