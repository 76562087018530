/* @flow */

import line_requested_delivery from './line_requested_delivery'

const line_confirmed_delivery = ({
  edit_property = 'overridden_delivery',
  key = 'line_confirmed_delivery',
  label = 'Conf. delivery',
  ...rest
}) => ({
  ...line_requested_delivery({ edit_property, key, label, ...rest }),
})

export default line_confirmed_delivery
