/* @flow */

import React from 'react'
import { Link } from 'react-router-dom'

import { PermissionContext } from '../../../../infrastructure/components/Authorization'

const AdminNavigation = () => {
  return (
    <div className="template-main-sidebar" style={{ borderRight: 0 }}>
      <nav className="template-main-navigation">
        <p className="menu-title">Traede Administration</p>
        <ul className="template-main-navigation-ul-handle">
          <li>
            <Link to="/admin/users" activeClassName="active">
              <span className="title">Users</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/apps" activeClassName="active">
              <span className="title">Apps</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/paying-customers" activeClassName="active">
              <span className="title">Paying Customers</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/elastic-integrity" activeClassName="active">
              <span className="title">Elastic Data Integrity</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/tablet-app-states" activeClassName="active">
              <span className="title">Tablet App States</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/feature-adoption" activeClassName="active">
              <span className="title">Feature Adoption</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/agents" activeClassName="active">
              <span className="title">Agents</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/queue" activeClassName="active">
              <span className="title">Queue</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/news" activeClassName="active">
              <span className="title">News</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/shopify-tools" activeClassName="active">
              <span className="title">Shopify tools</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/accounting-fixing-tools" activeClassName="active">
              <span className="title">Accounting fixing tools</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/miscellaneous-tools" activeClassName="active">
              <span className="title">Miscellaneous Tools</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default AdminNavigation
