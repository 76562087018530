/* @flow */

import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import InputField from '../../Rows/InputField'

import type { ColumnConfig } from '../../types'
import {
  addPrefixIfNoneExists,
  renderDate,
  Tooltip,
} from '../../../../../shared'

type Options = {}

const total_assortment_quantity: (options: Options) => ColumnConfig = ({
  assortmentTotalsMode = 'size',
}) => {
  const config = {
    editable: false,
    key: 'total_assortment_quantity',
    label: 'Total qty.',
  }

  // e.g. when doing POs it does not make sense to show total quantity per size
  // this is because the numbers wont make sense when being compared to the
  // `in stock` and `ordered` row numbers
  if (assortmentTotalsMode === 'no_size') {
    config.render = () => <div></div>
  }

  return config
}

export default total_assortment_quantity
