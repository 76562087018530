/* @flow */

import {
  getBusinessCentralCompanies,
  getBusinessCentralCustomerPostingGroups,
  getBusinessCentralVendorPostingGroups,
  getBusinessCentralGenBusinessPostingGroups,
  getBusinessCentralVatBusinessPostingGroups,
  getDataExportTargets,
  getDataExportSchemes,
  getErpAccounts,
  getErpApps,
  getErpB2cPaymentAcquirers,
  getErpB2cPaymentGateways,
  getErpBankAccounts,
  getErpCashBooks,
  getErpCompanies,
  getErpCostCenters,
  getErpCustomerPaymentJournals,
  getErpCustomerGroups,
  getErpDimensions,
  getErpLayouts,
  getErpVatCodes,
  getErpVatZones,
  getErpVoucherSeries,
  getKlaviyoLists,
  getOngoingWarehouses,
  getPensopayAccounts,
  getPicqerShippingProviders,
  getQuickpayAccounts,
  getFaireTaxonomies,
  getSendcloudShippingMethods,
  getShopifyMarkets,
  getShopifyShops,
  getZalandoAttributes,
  getZalandoLocations,
  getZalandoOutlines,
  getZalandoOutlineSchema,
  getZalandoSalesChannels,
  getAvailableWebshipperShippingRates,
  getWoocommerceShops,
} from './api'

import { quickCreateHook } from '../hooks'

const {
  hook: useErpAccounts,
  hookCached: useCachedErpAccounts,
  clearCache: clearCachedErpAccountsCache,
} = quickCreateHook(getErpAccounts, 'accounts', [])

export { useErpAccounts, useCachedErpAccounts, clearCachedErpAccountsCache }

const {
  hook: useErpB2cPaymentAcquirers,
  hookCached: useCachedErpB2cPaymentAcquirers,
  clearCache: clearCachedErpB2cPaymentAcquirersCache,
} = quickCreateHook(getErpB2cPaymentAcquirers, 'acquirers', [])

export {
  useErpB2cPaymentAcquirers,
  useCachedErpB2cPaymentAcquirers,
  clearCachedErpB2cPaymentAcquirersCache,
}

const {
  hook: useErpB2cPaymentGateways,
  hookCached: useCachedErpB2cPaymentGateways,
  clearCache: clearCachedErpB2cPaymentGatewaysCache,
} = quickCreateHook(getErpB2cPaymentGateways, 'gateways', [])

export {
  useErpB2cPaymentGateways,
  useCachedErpB2cPaymentGateways,
  clearCachedErpB2cPaymentGatewaysCache,
}

const {
  hook: useErpApps,
  hookCached: useCachedErpApps,
  clearCache: clearCachedErpAppsCache,
} = quickCreateHook(getErpApps, 'erp_apps', [], {
  v2: true,
})

export { useErpApps, useCachedErpApps, clearCachedErpAppsCache }

const {
  hook: useErpBankAccounts,
  hookCached: useCachedErpBankAccounts,
  clearCache: clearCachedErpBankAccountsCache,
} = quickCreateHook(getErpBankAccounts, 'accounts', [])

export {
  useErpBankAccounts,
  useCachedErpBankAccounts,
  clearCachedErpBankAccountsCache,
}

const {
  hook: useErpCashBooks,
  hookCached: useCachedErpCashBooks,
  clearCache: clearCachedErpCashBooksCache,
} = quickCreateHook(getErpCashBooks, 'cash_books', [])

export { useErpCashBooks, useCachedErpCashBooks, clearCachedErpCashBooksCache }

const {
  hook: useErpCustomerPaymentJournals,
  hookCached: useCachedErpCustomerPaymentJournals,
  clearCache: clearCachedErpCustomerPaymentJournalsCache,
} = quickCreateHook(getErpCustomerPaymentJournals, 'journals', [])

export {
  useErpCustomerPaymentJournals,
  useCachedErpCustomerPaymentJournals,
  clearCachedErpCustomerPaymentJournalsCache,
}

const {
  hook: useErpCustomerGroups,
  hookCached: useCachedErpCustomerGroups,
  clearCache: clearCachedErpCustomerGroupsCache,
} = quickCreateHook(getErpCustomerGroups, 'customer_groups', [])

export {
  useErpCustomerGroups,
  useCachedErpCustomerGroups,
  clearCachedErpCustomerGroupsCache,
}

const {
  hook: useErpCompanies,
  hookCached: useCachedErpCompanies,
  clearCache: clearCachedErpCompaniesCache,
} = quickCreateHook(getErpCompanies, 'companies', [])

export { useErpCompanies, useCachedErpCompanies, clearCachedErpCompaniesCache }

const {
  hook: useErpCostCenters,
  hookCached: useCachedErpCostCenters,
  clearCache: clearCachedErpCostCentersCache,
} = quickCreateHook(getErpCostCenters, 'cost_centers', [])

export {
  useErpCostCenters,
  useCachedErpCostCenters,
  clearCachedErpCostCentersCache,
}

const {
  hook: useErpDimensions,
  hookCached: useCachedErpDimensions,
  clearCache: clearCachedErpDimensionsCache,
} = quickCreateHook(getErpDimensions, 'dimensions', [])

export {
  useErpDimensions,
  useCachedErpDimensions,
  clearCachedErpDimensionsCache,
}

const {
  hook: useErpLayouts,
  hookCached: useCachedErpLayouts,
  clearCache: clearCachedErpLayoutsCache,
} = quickCreateHook(getErpLayouts, 'layouts', [])

export { useErpLayouts, useCachedErpLayouts, clearCachedErpLayoutsCache }

const {
  hook: useErpVatCodes,
  hookCached: useCachedErpVatCodes,
  clearCache: clearCachedErpVatCodesCache,
} = quickCreateHook(getErpVatCodes, 'vat_codes', [])

export { useErpVatCodes, useCachedErpVatCodes, clearCachedErpVatCodesCache }

const {
  hook: useErpVatZones,
  hookCached: useCachedErpVatZones,
  clearCache: clearCachedErpVatZonesCache,
} = quickCreateHook(getErpVatZones, 'vat_zones', [])

export { useErpVatZones, useCachedErpVatZones, clearCachedErpVatZonesCache }

const {
  hook: useErpVoucherSeries,
  hookCached: useCachedErpVoucherSeries,
  clearCache: clearCachedErpVoucherSeriesCache,
} = quickCreateHook(getErpVoucherSeries, 'voucher_series', [])

export {
  useErpVoucherSeries,
  useCachedErpVoucherSeries,
  clearCachedErpVoucherSeriesCache,
}

const {
  hook: usePicqerShippingProviders,
  hookCached: useCachedPicqerShippingProviders,
  clearCache: clearCachedPicqerShippingProvidersCache,
} = quickCreateHook(getPicqerShippingProviders, 'shipping_providers', [])

export {
  usePicqerShippingProviders,
  useCachedPicqerShippingProviders,
  clearCachedPicqerShippingProvidersCache,
}

const {
  hook: useDataExportTargets,
  hookCached: useCachedDataExportTargets,
  clearCache: clearCachedDataExportTargetsCache,
} = quickCreateHook(getDataExportTargets, 'data_export_targets', [])

export {
  useDataExportTargets,
  useCachedDataExportTargets,
  clearCachedDataExportTargetsCache,
}

const {
  hook: useDataExportSchemes,
  hookCached: useCachedDataExportSchemes,
  clearCache: clearCachedDataExportSchemesCache,
} = quickCreateHook(getDataExportSchemes, 'schemes', [])

export {
  useDataExportSchemes,
  useCachedDataExportSchemes,
  clearCachedDataExportSchemesCache,
}

const {
  hook: useKlaviyoLists,
  hookCached: useCachedKlaviyoLists,
  clearCache: clearCachedKlaviyoListsCache,
} = quickCreateHook(getKlaviyoLists, 'lists', [])

export { useKlaviyoLists, useCachedKlaviyoLists, clearCachedKlaviyoListsCache }

// FAIRE

const {
  hook: useFaireTaxonomies,
  hookCached: useCachedFaireTaxonomies,
  clearCache: clearCachedFaireTaxonomiesCache,
} = quickCreateHook(getFaireTaxonomies, 'taxonomies', [])

export {
  useFaireTaxonomies,
  useCachedFaireTaxonomies,
  clearCachedFaireTaxonomiesCache,
}

// ZALANDO

const {
  hook: useZalandoLocations,
  hookCached: useCachedZalandoLocations,
  clearCache: clearCachedZalandoLocationsCache,
} = quickCreateHook(getZalandoLocations, 'locations', [])

export {
  useZalandoLocations,
  useCachedZalandoLocations,
  clearCachedZalandoLocationsCache,
}

const {
  hook: useZalandoSalesChannels,
  hookCached: useCachedZalandoSalesChannels,
  clearCache: clearCachedZalandoSalesChannelsCache,
} = quickCreateHook(getZalandoSalesChannels, 'sales_channels', [])

export {
  useZalandoSalesChannels,
  useCachedZalandoSalesChannels,
  clearCachedZalandoSalesChannelsCache,
}

const {
  hook: useZalandoOutlines,
  hookCached: useCachedZalandoOutlines,
  clearCache: clearCachedZalandoOutlinesCache,
} = quickCreateHook(getZalandoOutlines, 'outlines', [])

export {
  useZalandoOutlines,
  useCachedZalandoOutlines,
  clearCachedZalandoOutlinesCache,
}

const {
  hook: useZalandoOutlineSchemas,
  hookCached: useCachedZalandoOutlineSchemas,
  clearCache: clearCachedZalandoOutlineSchemasCache,
} = quickCreateHook(getZalandoOutlineSchema, 'schema', null)

export {
  useZalandoOutlineSchemas,
  useCachedZalandoOutlineSchemas,
  clearCachedZalandoOutlineSchemasCache,
}

const {
  hook: useZalandoAttributes,
  hookCached: useCachedZalandoAttributes,
  clearCache: clearCachedZalandoAttributesCache,
} = quickCreateHook(getZalandoAttributes, 'attributes', [])

export {
  useZalandoAttributes,
  useCachedZalandoAttributes,
  clearCachedZalandoAttributesCache,
}

// Business Central

const {
  hook: useBusinessCentralCompanies,
  hookCached: useCachedBusinessCentralCompanies,
  clearCache: clearCachedBusinessCentralCompaniesCache,
} = quickCreateHook(getBusinessCentralCompanies, 'companies', [])

export {
  useBusinessCentralCompanies,
  useCachedBusinessCentralCompanies,
  clearCachedBusinessCentralCompaniesCache,
}

const {
  hook: useBusinessCentralCustomerPostingGroups,
  hookCached: useCachedBusinessCentralCustomerPostingGroups,
  clearCache: clearCachedBusinessCentralCustomerPostingGroupsCache,
} = quickCreateHook(
  getBusinessCentralCustomerPostingGroups,
  'posting_groups',
  []
)

export {
  useBusinessCentralCustomerPostingGroups,
  useCachedBusinessCentralCustomerPostingGroups,
  clearCachedBusinessCentralCustomerPostingGroupsCache,
}

const {
  hook: useBusinessCentralVendorPostingGroups,
  hookCached: useCachedBusinessCentralVendorPostingGroups,
  clearCache: clearCachedBusinessCentralVendorPostingGroupsCache,
} = quickCreateHook(getBusinessCentralVendorPostingGroups, 'posting_groups', [])

export {
  useBusinessCentralVendorPostingGroups,
  useCachedBusinessCentralVendorPostingGroups,
  clearCachedBusinessCentralVendorPostingGroupsCache,
}

const {
  hook: useBusinessCentralGenBusinessPostingGroups,
  hookCached: useCachedBusinessCentralGenBusinessPostingGroups,
  clearCache: clearCachedBusinessCentralGenBusinessPostingGroupsCache,
} = quickCreateHook(
  getBusinessCentralGenBusinessPostingGroups,
  'posting_groups',
  []
)

export {
  useBusinessCentralGenBusinessPostingGroups,
  useCachedBusinessCentralGenBusinessPostingGroups,
  clearCachedBusinessCentralGenBusinessPostingGroupsCache,
}

const {
  hook: useBusinessCentralVatBusinessPostingGroups,
  hookCached: useCachedBusinessCentralVatBusinessPostingGroups,
  clearCache: clearCachedBusinessCentralVatBusinessPostingGroupsCache,
} = quickCreateHook(
  getBusinessCentralVatBusinessPostingGroups,
  'posting_groups',
  []
)

export {
  useBusinessCentralVatBusinessPostingGroups,
  useCachedBusinessCentralVatBusinessPostingGroups,
  clearCachedBusinessCentralVatBusinessPostingGroupsCache,
}

// Shopify

const {
  hook: useShopifyShops,
  hookCached: useCachedShopifyShops,
  clearCache: clearShopifyShopsCache,
} = quickCreateHook(getShopifyShops, 'shops', [])

export { useShopifyShops, useCachedShopifyShops, clearShopifyShopsCache }

const { hook: useShopifyMarkets } = quickCreateHook(
  getShopifyMarkets,
  'markets',
  []
)

export { useShopifyMarkets }

// Woocommerce

const {
  hook: useWoocommerceShops,
  hookCached: useCachedWoocommerceShops,
  clearCache: clearCachedWoocommerceShopsCache,
} = quickCreateHook(getWoocommerceShops, 'shops', [])

export {
  useWoocommerceShops,
  useCachedWoocommerceShops,
  clearCachedWoocommerceShopsCache,
}

const {
  hook: useSendcloudShippingMethods,
  hookCached: useCachedSendcloudShippingMethods,
  clearCache: clearCachedSendcloudShippingMethodsCache,
} = quickCreateHook(getSendcloudShippingMethods, 'shipping_methods', [])

export {
  useSendcloudShippingMethods,
  useCachedSendcloudShippingMethods,
  clearCachedSendcloudShippingMethodsCache,
}

const {
  hook: useAvailableWebshipperShippingRates,
  hookCached: useCachedAvailableWebshipperShippingRates,
  clearCache: clearCachedAvailableWebshipperShippingRatesCache,
} = quickCreateHook(getAvailableWebshipperShippingRates, 'shipping_rates', [])

export {
  useAvailableWebshipperShippingRates,
  useCachedAvailableWebshipperShippingRates,
  clearCachedAvailableWebshipperShippingRatesCache,
}

// Ongoing

const {
  hook: useOngoingWarehouses,
  hookCached: useCachedOngoingWarehouses,
  clearCache: clearCachedOngoingWarehousesCache,
} = quickCreateHook(getOngoingWarehouses, 'warehouses', [])

export {
  useOngoingWarehouses,
  useCachedOngoingWarehouses,
  clearCachedOngoingWarehousesCache,
}

// Quickpay

const {
  hook: useQuickpayAccounts,
  hookCached: useCachedQuickpayAccounts,
  clearCache: clearQuickpayAccountsCache,
} = quickCreateHook(getQuickpayAccounts, 'accounts', [], {
  v2: true,
})

export {
  useQuickpayAccounts,
  useCachedQuickpayAccounts,
  clearQuickpayAccountsCache,
}

// Pensopay

const {
  hook: usePensopayAccounts,
  hookCached: useCachedPensopayAccounts,
  clearCache: clearCachedPensopayAccountsCache,
} = quickCreateHook(getPensopayAccounts, 'accounts', [], {
  v2: true,
})

export {
  usePensopayAccounts,
  useCachedPensopayAccounts,
  clearCachedPensopayAccountsCache,
}
