/* @flow */

import * as React from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { type Node, Suspense } from 'react'
import { connect } from 'react-redux'
import msg from '../infrastructure/modules/msg'
import Globalize from '../infrastructure/modules/globalize'
import useRefValue from '../infrastructure/hooks/ref_value'
import moment from 'moment-timezone'
import sortBy from 'lodash/sortBy'
import mixpanel from 'mixpanel-browser'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { refreshSession as refreshSessionAction } from '../infrastructure/actions/session'
import querystring from 'qs'

import type { Id } from './types'

import {
  parseNumericStrings,
  parseNumericStringsList,
} from '../infrastructure/utilities'

export { parseNumericStrings, parseNumericStringsList }

let store = null
export const setStore = s => {
  store = s
}

const refreshSession = () => {
  store.dispatch(refreshSessionAction())
}

export const userHasFlag = (user, flags) => {
  if (!user) {
    return false
  }

  const featureFlags = user.feature_flags || {}

  if (Array.isArray(flags)) {
    for (let flag of flags) {
      if (featureFlags[flag] === true) {
        return true
      }
    }
  } else {
    const value = featureFlags[flags]

    return value === true
  }

  return false
}

export const webshopV1Brands = [
  8225, 12300, 13721, 16423, 16799, 24369, 25240, 26727, 27273, 29206, 29882,
  31734, 31740, 32438, 32465, 35795, 37656, 40334, 40589, 40610, 40689, 40823,
  47841, 49391, 49496, 51557, 52268, 52269, 54041, 54042, 55446, 55447, 56485,
  68502, 71440, 81748, 91818, 93183, 96129, 98649, 108652, 109177, 113217,
  114770, 120227, 120308, 120309, 121894, 125096, 126375, 129782, 141858,
  144018, 152120, 156066, 156416, 169552, 169553, 177043, 179027, 186804,
  189069, 193256, 194209, 201456, 205699, 219614, 244664, 249872, 266501,
  266502, 273208, 273404,
]

const selectionBrands = [10919, 24824]

const SplitComponent = ({
  false: OldComponent,
  entity = false,
  entityOrUser = false,
  splitKey,
  not = false,
  true: NewComponent,
  ...rest
}: {
  false?: React.Node,
  entity?: boolean,
  splitKey: string | Array<string>,
  true?: React.Node,
}) => {
  const { brand, user } = React.useContext(SessionContext)
  let hasFlag = false

  let specialOverride = null
  if (splitKey === 'webshop_2') {
    const match = rest.match
    const params = match ? match.params : {}
    const brandId = params.brandId

    if (!webshopV1Brands.includes(parseInt(brandId))) {
      specialOverride = true
    }
  } else if (splitKey === 'selection') {
    const match = rest.match
    const params = match ? match.params : {}
    const brandId = params.brandId

    if (selectionBrands.includes(parseInt(brandId))) {
      specialOverride = true
    }
  }

  if (specialOverride === null) {
    // check if not anonoymous
    if (entityOrUser) {
      const brandHasFeatureFlag = brand ? userHasFlag(brand, splitKey) : false
      const userHasFeatureFlag = user ? userHasFlag(user, splitKey) : false

      hasFlag = brandHasFeatureFlag || userHasFeatureFlag
    } else {
      const takeFeatureFlagsFrom = entity ? brand : user
      hasFlag = userHasFlag(takeFeatureFlagsFrom, splitKey)
    }
  } else {
    hasFlag = specialOverride
  }

  if (not) {
    hasFlag = !hasFlag
  }

  const render = Input => {
    if (React.isValidElement(Input)) {
      return Input
    } else {
      return <Input {...rest} />
    }
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {hasFlag && NewComponent && render(NewComponent)}
      {!hasFlag && OldComponent && render(OldComponent)}
    </Suspense>
  )
}

export { SplitComponent }

export { useRefValue }

export const SuspenseWrap = children => (
  <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
)

export const withSuspense = InputComponent => props => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <InputComponent {...props} />
    </Suspense>
  )
}

const SessionContext = React.createContext({})

export { msg, refreshSession, SessionContext }

export const renderDate = (
  date: ?string | ?Date,
  format: 'short' | 'long' | 'month_year' | 'week' = 'short'
) => {
  const formatter = Globalize.dateFormatter({ date: format })

  if (date instanceof Date) {
    return formatter(date)
  }

  if (!date) {
    return ''
  }

  const parser = Globalize.dateParser({
    raw:
      (typeof date === 'string' && date.indexOf(' ')) !== -1
        ? 'yyyy-MM-dd H:m:s'
        : 'yyyy-MM-dd',
  })

  return formatter(parser(date))
}

export const renderDateTime = (
  date: ?string | ?Date,
  format: 'short' | 'long' = 'short'
) => {
  const formatter = Globalize.dateFormatter({ datetime: format })

  if (date instanceof Date) {
    return formatter(date)
  }

  if (!date) {
    return ''
  }

  const parser = Globalize.dateParser({
    raw: date.indexOf(' ') !== -1 ? 'yyyy-MM-dd H:m:s' : 'yyyy-MM-dd',
  })

  return formatter(parser(date))
}

export const convertFromUserDateToServerDate = (inputDate, inputTimezone) => {
  return moment
    .tz(inputDate, 'YYYY-MM-DD H:m:s Z', inputTimezone)
    .utc()
    .toISOString()
}

export const Tooltip = ({
  children,
  id,
  placement = 'top',
  tip,
}: {
  children?: React.Node,
  id: string,
  placement?: 'top' | 'right' | 'bottom' | 'left',
  tip: string | Node,
}) => {
  const tooltip = <BootstrapTooltip id={id}>{tip}</BootstrapTooltip>

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement={placement}
      overlay={tooltip}
    >
      {children}
    </OverlayTrigger>
  )
}

export const addPrefixIfNoneExists = (number, prefix) => {
  const regex = /[^\d]+/

  return number.match(regex) ? number : `${prefix}${number}`
}

export const HelpIndicator = ({
  children,
  id,
  size = 'normal',
  ...rest
}: {
  children?: React.Node,
  id: string,
  size?: 'normal' | 'sm',
}) => {
  return (
    <Tooltip id={id} tip={children}>
      <HelpIndicatorContainer size={size} {...rest}>
        ?
      </HelpIndicatorContainer>
    </Tooltip>
  )
}

export const HoverableStyle = styled.span`
  text-decoration: underline;
`

export const FakeLinkStyle = styled.span`
  color: #0d638f;
  cursor: pointer;
  text-decoration: underline;
`

const helpIndicatorSizeToStyle = ({ size }) => {
  switch (size) {
    default:
      return css`
        padding: 1px 6px;
      `

    case 'sm':
      return css`
        font-size: 10px;
        padding: 1px 3px;
      `

    case 'xs':
      return css`
        font-size: 8px;
        padding: 1px;
      `
  }
}

const HelpIndicatorContainer = styled.div`
  background: #e6e6e6;
  display: inline-block;
  font-weight: bold;
  text-align: center;

  ${props => helpIndicatorSizeToStyle(props)};

  @media print {
    display: none !important;
  }
`

export const createViewPublicFile = ({ name, code }) => {
  const url = `${process.env.FULL_API_URL}/documents/view?name=${name}&code=${code}`
  return url
}

export const downloadPublicFile = ({
  name,
  code,
}: {
  name: string,
  code: string,
}) => {
  const url = `${process.env.FULL_API_URL}/documents/download?name=${name}&code=${code}`
  // Trick for making downloadable link
  var a = document.createElement('a')
  a.href = url
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const ConditionalWrap = ({
  children,
  condition,
  wrap,
}: {
  children: React.Node,
  condition: boolean,
  wrap: Function,
}) => (condition ? wrap(children) : children)

export const isValidDate = (input, format) =>
  moment(input, format, true).isValid()

export const parseDateInput = date => {
  if (date instanceof moment) {
    return date.format('YYYY-MM-DD')
  }

  return isValidDate(date) ? moment(date).format('YYYY-MM-DD') : null
}

export const createConfiguredVariantId = configuredValues => {
  const id = []
  for (let row of configuredValues) {
    id.push(`${row.attribute_id}:${row.attribute_value_id}`)
  }

  return id.join(',')
}

export const renderFileSize = (sizeInBytes: number, options: Object = {}) => {
  const digitsAfterDecimalPoint =
    options.digitsAfterDecimalPoint !== undefined
      ? options.digitsAfterDecimalPoint
      : 2
  const scale = Math.floor(Math.log(sizeInBytes) / Math.log(1024))

  return (
    (sizeInBytes / Math.pow(1024, scale)).toFixed(digitsAfterDecimalPoint) +
    ' ' +
    ['B', 'KB', 'MB', 'GB'][scale]
  )
}

export const stripHtml = (html: string): string => {
  const element = document.createElement('div')
  element.innerHTML = html
  return element.textContent || element.innerText || ''
}

export const createLevelVariantObject = obj => ({
  variant_id: obj.variant_id,
  batch: obj.batch,
  batch_id: obj.batch_id,
  inventory_location_id: obj.inventory_location_id,
})

export const createLevelVariantKeyFromObject = obj =>
  createLevelVariantKey(obj.variant_id, obj.inventory_location_id, obj.batch)

export const createLevelVariantKey = (
  variantId: Id,
  inventoryLocationId: Id,
  batch: null | string
) => {
  return `${variantId}-${inventoryLocationId}-${
    batch === null ? 'null' : batch
  }`
}

export const createDropKey = (dropId, dropDeliveryId) =>
  `${dropId}-${dropDeliveryId}`

export const useShowModal = <T = boolean>(initialShow: T = false) => {
  const [show, setShow] = React.useState<T>(initialShow)

  const toggle = React.useCallback(
    (argShow?: T) => {
      setShow(argShow !== undefined ? argShow : !show)
    },
    [show, setShow]
  )

  return [show, toggle, setShow]
}

export const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>'
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  )
}

// ref: https://stackoverflow.com/a/33928558/602488
export const copyToClipboard = text => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return prompt('Copy to clipboard: Ctrl+C, Enter', text)
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export const WarningLabel = ({
  children,
  id,
  severity = 'warning',
  fontSize = 9,
  style,
}) => {
  return (
    <span style={style}>
      <Tooltip id={id} tip={children}>
        <span
          className="glyphicon glyphicon-warning-sign"
          style={{
            color: severity === 'warning' ? 'orange' : 'red',
            fontSize,
          }}
        />
      </Tooltip>
    </span>
  )
}

export const getMostFrequentOccurrence = (
  listOfObjects,
  propertiesToLookFor,
  entryIdProperty = 'id'
) => {
  const count = {}

  for (let entry of listOfObjects) {
    const data = {}

    for (let property of propertiesToLookFor) {
      data[property] = entry[propertiesToLookFor]
    }

    const key = JSON.stringify(data)

    if (!count[key]) {
      count[key] = {
        entry_ids: [],
        data: data,
        count: 0,
      }
    }

    count[key].entry_ids.push(entry[entryIdProperty])
    count[key].count++
  }

  const sorted = sortBy(Object.values(count), entry => -entry.count)

  return sorted[0]
}

export const mixpanelTrack = (...args) => {
  if (process.env.NODE_ENV === 'production' && process.env.MIXPANEL_TOKEN) {
    return mixpanel.track(...args)
  }
}

export const createNullSafeKey = key => (key === null ? '__traede_null' : key)

export const parseNullSafeKey = key => (key === '__traede_null' ? null : key)

export const useCancellableRequest = () => {
  const cancelPreviousRequestRef = React.useRef(null)

  const cancelAndDoRequest = React.useCallback(
    doRequest => {
      if (cancelPreviousRequestRef.current !== null) {
        cancelPreviousRequestRef.current.call()
      }

      const cancelTokenSource = axios.CancelToken.source()
      cancelPreviousRequestRef.current = () => cancelTokenSource.cancel()

      const axiosOptions = {
        cancelToken: cancelTokenSource.token,
        retry: 3,
      }

      return doRequest(axiosOptions).then(response => {
        if (response.isCancel) {
          // Request cancelled, do nothing
          return
        }

        cancelPreviousRequestRef.current = null

        return response
      })
    },
    [cancelPreviousRequestRef]
  )

  return [cancelAndDoRequest]
}

export const useUrlState = (history, location, baseUrl, options = {}) => {
  const { currentTab, currentSubTab } = options

  const currentQuery = React.useMemo(() => {
    return querystring.parse(location.search.slice(1))
  }, [location.search])

  const createLink = React.useCallback(
    updates => {
      let url = baseUrl

      if (updates.tab) {
        url += `/${updates.tab}`
      }

      if (updates.tab && updates.subTab) {
        url += `/${updates.subTab}`
      }

      if (updates.query) {
        url += `?${querystring.stringify({
          ...currentQuery,
          ...updates.query,
        })}`
      }

      return url
    },
    [baseUrl, currentQuery]
  )

  const onUpdateQueryString = React.useCallback(
    (updates, replace = false) => {
      const newUrl = createLink({
        tab: currentTab,
        subTab: currentSubTab,
        query: updates,
      })

      if (replace) {
        history.replace(newUrl)
      } else {
        history.push(newUrl)
      }
    },
    [history, createLink, currentTab, currentSubTab]
  )

  const onNavigate = React.useCallback(
    (updates, replace = false) => {
      const newUrl = createLink(updates)

      if (replace) {
        history.replace(newUrl)
      } else {
        history.push(newUrl)
      }
    },
    [history, createLink]
  )

  return {
    currentQuery,
    createLink,
    onNavigate,
    onUpdateQueryString,
  }
}

export const roundToFour = num => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000
}
