/* @flow */

import uuid from 'uuid'
import isPlainObject from 'lodash/isPlainObject'
import sortBy from 'lodash/sortBy'

import type {
  ColumnConfig,
  ColumnValues,
  Row,
  RowConfig,
  SplitConfig,
  SubSection,
} from '../../types'
import type { Product, Variant } from '../../../../../types'

export const createColumnKey = (columnValues: ColumnValues) => {
  let columnKey = ''
  for (let k in columnValues) {
    const value = columnValues[k]
    const valueSignature =
      isPlainObject(value) || Array.isArray(value)
        ? JSON.stringify(value)
        : value

    columnKey += `${k}:${valueSignature}`
  }

  return columnKey
}

export const createColumnsDependencyGraph = (
  columns: Array<ColumnConfig>,
  matrix: boolean
) => {
  const resolved = []
  const columnsCopy = [...columns]
  const ordered = []

  let i = 0
  while (columnsCopy.length !== ordered.length && i < 20) {
    for (let column of columnsCopy) {
      let dependsOn = column.depends_on || []

      if (typeof dependsOn === 'function') {
        dependsOn = dependsOn({ matrix })
      }

      let passes = true
      for (let dependency of dependsOn) {
        if (!resolved.includes(dependency)) {
          passes = false
          break
        }
      }

      if (passes) {
        ordered.push(column)
        resolved.push(column.key)
      }
    }

    i++
  }

  return ordered
}

export const createRows = (
  product: Product,
  rowConfig: RowConfig,
  columns: Array<ColumnConfig>,
  dataOfSection,
  // we used to use variantsById here, but it's important that rows are calculating using the
  // same order that variants were sorted with in the backend. An example of this is product ID 512282
  // on taisho that has 3 variants with 3 different prices. If we use variantsById then the prices are
  // not a perfect diagonal line
  variants: { [string]: Variant },
  matrix: boolean,
  splitKey: string,
  source: string,
  splitBy: ?Array<SplitConfig>
): Array<Row> => {
  const dataByVariantId = {}
  const rowValuesByVariantId = {}
  const rowValuesCollisByVariantId = {}

  if (
    rowConfig.data_source === '__product' ||
    rowConfig.data_source === '__variant'
  ) {
    for (let variant of variants) {
      rowValuesByVariantId[variant.id] = rowConfig.value
        ? rowConfig.value({ product, variant })
        : variant[rowConfig.key]
    }
  } else if (rowConfig.data_source) {
    const dataForRow = dataOfSection[rowConfig.data_source] || {}

    for (let variantId in dataForRow) {
      let dataForVariant = dataForRow[variantId] || []

      if (splitBy) {
        dataForVariant = dataForVariant.filter(obj => {
          const splitData = {}
          for (let split of splitBy) {
            Object.assign(splitData, getSplitOfObject(split, obj))
          }

          return JSON.stringify(splitData) === splitKey
        })
      }

      const variant = variants.find(v => v.id == variantId)

      if (!dataByVariantId[variant.id]) {
        dataByVariantId[variant.id] = []
      }

      dataByVariantId[variant.id] =
        dataByVariantId[variant.id].concat(dataForVariant)

      if (rowConfig.aggregate) {
        const currentAggregateValue = rowValuesByVariantId[variant.id] || 0
        const aggregateValue = dataForVariant.reduce(
          (carry, r) => (carry += r[rowConfig.aggregate]),
          0
        )

        rowValuesByVariantId[variant.id] =
          currentAggregateValue + aggregateValue
      } else {
        rowValuesByVariantId[variant.id] = dataForVariant || []
      }
    }
  }

  const columnsForRow = columns.filter(c => c.rows.includes(rowConfig.key))
  const columnsForRowByDependencyGraph = createColumnsDependencyGraph(
    columnsForRow,
    matrix
  )
  const splittableColumns = columnsForRowByDependencyGraph.filter(
    c => c.split_by_values
  )
  const nonSplittableColumns = columnsForRowByDependencyGraph.filter(
    c => !c.split_by_values
  )

  const newRowsByColumnKey = {}

  for (let variant of variants) {
    const splittableColumnValues = resolveColumnValues(
      {},
      splittableColumns,
      {
        data: dataOfSection,
        matrix,
        rowKey: rowConfig.key,
        variant: variant,
      },
      {},
      {}
    )

    const columnKey = createColumnKey(splittableColumnValues)
    let isAssortmentRow = false
    let assortmentVariant = null
    let assortmentVariantId = null

    if (variant.assortment_id) {
      if (rowConfig.expand_assortments) {
        isAssortmentRow = true
        assortmentVariant = variant
        assortmentVariantId = variant.id

        // We previously tried to add this even when expand_assortments=false.
        // But this caused issues where non-quantity columns (SKU, Delivery status etc.)
        // suddenly generated multiple rows. We only did that to make sure total_quantity
        // column would calculate correctly if multiple assortments were mixed on the same row.
        // Ref: https://app.shortcut.com/traede/story/51913/products-inventory-list-looks-strange
        columnKey += `assortment:${variant.assortment_id}`
      }
    }

    // NOTE: The below is removed again, see comment above
    // previously we only added this key if expand_assortments=true. but since
    // you can have different assortments with different quantities on the same
    // row, we need to make sure that every assortment is a new row. otherwise,
    // we need to figure out how total_quantity column can calculate correctly
    // if different assortments are mixed on the same row.
    // columnKey += `assortment:${variant.assortment_id}`

    if (!newRowsByColumnKey[columnKey]) {
      const nonSplittableColumnValues = resolveColumnValues(
        splittableColumnValues,
        nonSplittableColumns,
        {
          data: dataOfSection,
          matrix,
          rowKey: rowConfig.key,
          variants: variants,
        },
        rowValuesByVariantId,
        rowValuesCollisByVariantId
      )

      newRowsByColumnKey[columnKey] = {
        ...rowConfig,
        assortmentVariant,
        assortmentVariantId,
        columnKey: columnKey,
        columnValues: nonSplittableColumnValues,
        dataByVariantId: JSON.parse(JSON.stringify(dataByVariantId)),
        isAssortmentRow,
        // lines will be added outside of this function
        lines: [],
        linesByVariantId: {},
        id: uuid(),
        rowValuesByVariantId: JSON.parse(JSON.stringify(rowValuesByVariantId)),
        rowValuesCollisByVariantId,
        source,
      }

      // Colli is used to ensure that things are sold in bulk. Assortments is inherintly the same,
      // so we do not wish for assortments to also be sold using "colli"
      if (
        isAssortmentRow &&
        newRowsByColumnKey[columnKey].enable_colli === true
      ) {
        newRowsByColumnKey[columnKey].enable_colli = false
      }

      if (newRowsByColumnKey[columnKey].enable_colli === true) {
        newRowsByColumnKey[columnKey].edit_colli_property =
          rowConfig.edit_colli_property
            ? rowConfig.edit_colli_property
            : 'colli'
      }

      //if (rowConfig.editable) {
      newRowsByColumnKey[columnKey].editable_variants = []
      //}
    }

    //if (rowConfig.editable) {
    newRowsByColumnKey[columnKey].editable_variants.push(variant.id)
    //}
  }

  return Object.values(newRowsByColumnKey)
}

export const createSubSection = (
  key: $PropertyType<SubSection, 'subSectionKey'>,
  label: $PropertyType<SubSection, 'subSectionLabel'>,
  data: $PropertyType<SubSection, 'subSectionSplitData'>,
  rows: Array<Row>
): SubSection => ({
  rows,
  subSectionKey: key,
  subSectionLabel: label,
  subSectionSplitData: data,
})

export const resolveColumnValues = (
  currentColumnValues: ColumnValues = {},
  columnsByDependencyGraph: Array<ColumnConfig>,
  valueInputData,
  rowValuesByVariantId,
  rowValuesCollisByVariantId
): ColumnValues => {
  const updatedValues = { ...currentColumnValues }

  const columnsCopy = [...columnsByDependencyGraph]
  while (columnsCopy.length > 0) {
    const column = columnsCopy.shift()

    // For some columns, like order line text, the value will come from lines.
    // However, for rendering purposes we still want to "ready the value" to
    // avoid type errors. The value will be inputted later when we divide order
    // lines into sections. Until then we will create an empty array as placeholder
    const value = column.value
      ? column.value({
          columnValues: updatedValues,
          rowValuesByVariantId,
          rowValuesCollisByVariantId,
          ...valueInputData,
        })
      : // if there is no default value then the row will unmount and mount when the first
        // line is created - we need to keep the structure of the value the same if there is
        // a line or not
        column.default_value

    updatedValues[column.key] = value
  }

  return updatedValues
}

const getSplitOfObject = (split, dataEntry) => {
  const splitValue = dataEntry[split.property] || null

  return split.data
    ? split.data({
        dataEntry,
      })
    : {
        [split.property]: splitValue,
      }
}

export const ensureRowGroup = rowGroup => {
  let ensuredRowGroup = rowGroup

  if (rowGroup.type !== 'group') {
    ensuredRowGroup = {
      type: 'group',
      configs: [rowGroup],
      show_label_filter: rowGroup.show_label_filter,
    }
  }

  return ensuredRowGroup
}

export const findAllSubsectionSplitsFromInput = (
  input: Array<Array<Array<Object>>>,
  splitConfig: ?Array<SplitConfig>,
  tableData: { [string]: mixed }
) => {
  let splitKeys

  if (splitConfig) {
    const splitValues = {}

    for (let dataSource of input) {
      for (let dataEntries of dataSource) {
        if (dataEntries) {
          for (let dataEntry of dataEntries) {
            const splitData = {}
            const label = []

            for (let split of splitConfig) {
              // some times a split might have multiple properties, e.g. when ordering
              // inventory location + drop are the same.
              const dataOfSplit = getSplitOfObject(split, dataEntry)

              Object.assign(splitData, dataOfSplit)

              let labelOfProperty = split.label
                ? split.label({
                    data: dataOfSplit,
                    tableData,
                  })
                : dataOfSplit[split.property]

              if (labelOfProperty === null) {
                labelOfProperty = split.null_label
              }

              label.push(labelOfProperty)
            }

            const key = JSON.stringify(splitData)

            if (!splitValues[key]) {
              splitValues[key] = {
                data: splitData,
                key: key,
                label: label.join(', '),
              }
            }
          }
        }
      }
    }

    splitKeys = Object.values(splitValues)

    // when we have configured a split config we always don't want
    // any traede_no_splits. This is because when a line is added
    // to a traede_no_splits section it will automatically remove the
    // section and turn it into an actual split causing demount
    if (splitKeys.length === 0) {
      const defaultSplitData = {}
      const label = []
      for (let split of splitConfig) {
        const defaultSplitValue = split.default_value || null
        const defaultSplitLabel = split.default_value || split.null_label

        defaultSplitData[split.property] = defaultSplitValue
        label.push(defaultSplitLabel)
      }

      splitKeys = [
        {
          key: JSON.stringify(defaultSplitData),
          label: label.join(', '),
          data: defaultSplitData,
        },
      ]
    }
  }

  if (!splitKeys || splitKeys.length === 0) {
    splitKeys = [{ key: 'traede_no_split', label: '', data: null }]
  }

  return splitKeys
}

export const sortSubsections = (
  subSections: Array<SubSection>
): Array<SubSection> => {
  return sortBy(subSections, subSection => {
    if (
      subSection.subSectionKey === 'traede_no_split' ||
      subSection.subSectionKey === null
    ) {
      return -1000000
    }

    return subSection.subSectionLabel
  })
}
