/* @flow */

import api from '../api'

import type {
  ApiResponse,
  DataTableAttribute,
  DataTableRequestOptions,
  Id,
  Invoice,
  Shipment,
  ShipmentElastic,
} from '../types'

export const getShipment = (
  shipmentId: Id,
  options?: Object
): ApiResponse<{ shipment: Shipment }> =>
  api.get(`/shipments/${shipmentId}`, {
    query: options,
  })

export const getShipmentsList = (
  options?: DataTableRequestOptions
): ApiResponse<{ shipments: Array<ShipmentElastic>, total: number }> =>
  api.get('/shipments/list', {
    query: options,
  })

export const getShipmentUiView = (id: Id) => api.get(`/shipments/${id}/ui-view`)

export const getOrderShipments = (
  orderId: Id,
  options?: DataTableRequestOptions
): ApiResponse<{ shipments: Array<ShipmentElastic>, total: number }> =>
  api.get(`/orders/${orderId}/shipments`, {
    query: options,
  })

export const getShipmentsAttributes = (): ApiResponse<{
  attributes: Array<DataTableAttribute>,
}> => api.get('/shipments/attributes')

export const createShipment = (orderId: Id, data, options = {}) =>
  api.post(`/orders/${orderId}/shipments`, {
    body: {
      shipment: data,
    },
    ...options,
  })

export const calculateShipmentAvailability = orderId =>
  api.post(`/shipments/${orderId}/calculate-shipment-availability-v2`)

export const calculateShipmentSettings = orderId =>
  api.post(`/shipments/${orderId}/calculate-shipment-settings`)

export const getShipmentDeliveryNotesList = (
  options?: DataTableRequestOptions
): ApiResponse<{ shipments: Array<Object>, total: number }> =>
  api.get('/shipments/delivery-notes/list', {
    query: options,
  })

export const getShipmentDeliveryNotesAttributes = (): ApiResponse<{
  attributes: Array<DataTableAttribute>,
}> => api.get('/shipments/delivery-notes/attributes')

export const approveDeliveryNotes = (ids: Array<Id>): ApiResponse<null> =>
  api.post(`/shipments/delivery-notes/approve`, {
    body: {
      delivery_notes: ids,
    },
  })

export const getShipmentDeliveryNote = (
  shipmentDeliveryNoteId: Id,
  options?: Object
) =>
  api.get(`/shipments/delivery-notes/${shipmentDeliveryNoteId}`, {
    query: options,
  })

// Shipment delivery notes

export const createShipmentDeliveryNote = data =>
  api.post('/shipments/delivery-notes', {
    body: {
      shipment_delivery_note: data,
    },
  })

export const createShipmentDeliveryNoteV2 = data =>
  api.post('/shipments/delivery-notes-v2', {
    body: {
      shipment_delivery_note: data,
    },
  })

export const updateShipmentDeliveryNoteV2 = (
  shipmentDeliveryNoteId: Id,
  data,
  subUserId,
  deviceId,
  onConflict
) =>
  api.put(`/shipments/delivery-notes-v2/${shipmentDeliveryNoteId}`, {
    body: {
      shipment_delivery_note: data,
      sub_user_id: subUserId,
      device_id: deviceId,
    },
    statusCodeCallback: {
      code: 250000,
      callback: onConflict,
    },
  })

export const updateShipmentDeliveryNotesBulk = updates =>
  api.put(`/shipments/delivery-notes-v2`, {
    body: {
      shipment_delivery_notes: updates,
    },
  })

export const deleteShipmentDeliveryNote = (id: Id, reopenShipment: boolean) =>
  api.delete(`/shipments/${id}/delete-delivery-note`, {
    body: {
      reopen_shipment: reopenShipment,
    },
  })

// Shipping methods

export const getShippingMethods = (options?: BrunoRequestOptions) =>
  api.get('/shipments/methods', {
    query: options,
  })

export const createShippingMethod = (data: $Shape<ShippingMethod>) =>
  api.post('/shipments/methods', {
    body: {
      shipping_method: data,
    },
  })

export const updateShippingMethod = (id: Id, data: $Shape<ShippingMethod>) =>
  api.put(`/shipments/methods/${id}`, {
    body: {
      shipping_method: data,
    },
  })

export const deleteShippingMethod = (id: Id) =>
  api.delete(`/shipments/methods/${id}`)

export const calculateBulkShippingOptions = (axiosOptions, orders) =>
  api.post('/shipments/calculate-bulk-shipping-options', {
    body: {
      orders,
    },
    ...axiosOptions,
  })

// Shipping code conversions

export const getShippingCodeConversions = (axiosOptions = {}, options = {}) =>
  api.get(`/shipments/shipping-code-conversions`, {
    ...axiosOptions,
    query: options,
  })

export const createShippingCodeConversion = (axiosOptions, conversion) =>
  api.post('/shipments/shipping-code-conversions', {
    ...axiosOptions,
    body: {
      conversion: conversion,
    },
  })

export const updateShippingCodeConversion = (axiosOptions, id, conversion) =>
  api.put(`/shipments/shipping-code-conversions/${id}`, {
    ...axiosOptions,
    body: {
      conversion: conversion,
    },
  })

export const deleteShippingCodeConversion = (axiosOptions, id) =>
  api.delete(`/shipments/shipping-code-conversions/${id}`, {
    ...axiosOptions,
  })

export const createShippingCodeConversionAndPush3pl = conversion =>
  api.post('/shipments/shipping-code-conversions/create-and-push-3pl', {
    body: {
      conversion,
    },
  })

export const createShippingCodeConversionAndPushFreight = conversion =>
  api.post(`/shipments/shipping-code-conversions/create-and-push-freight`, {
    body: {
      conversion,
    },
  })

export const repushShipment3pl = shipments =>
  api.post(`/shipments/repush-3pl`, {
    body: {
      shipments,
    },
  })

export const exportShipmentEdi = (shipmentId: Id, connection: string) =>
  api.post(`/edi/shipments/export`, {
    body: {
      connection,
      shipment_id: shipmentId,
    },
  })

export const fixShipmentPush = (id: Id, shipment) =>
  api.put(`/shipments/${id}/fix-shipment-push`, {
    body: {
      shipment,
    },
  })

export const skipShipmentPush = (id: Id) =>
  api.put(`/shipments/${id}/skip-shipment-push`)

export const fixLabel = (id: Id, shipment) =>
  api.post(`/shipments/${id}/fix-label`, {
    body: {
      shipment,
    },
  })

export const cancelLabel = (id: Id) => api.post(`/shipments/${id}/cancel-label`)

export const cancelShipmentLabels = ids =>
  api.post('/shipments/cancel-labels', {
    body: {
      shipment_delivery_note_ids: ids,
    },
  })

// Colli type

export const getColliTypes = (options?: BrunoRequestOptions) =>
  api.get('/shipments/colli-types')

export const createColliType = (data: $Shape<ColliType>) =>
  api.post('/shipments/colli-types', {
    body: {
      colli_type: data,
    },
  })

export const updateColliType = (id: Id, data: $Shape<ColliType>) =>
  api.put(`/shipments/colli-types/${id}`, {
    body: {
      colli_type: data,
    },
  })

export const deleteColliType = (id: Id, data: $Shape<ColliType>) =>
  api.delete(`/shipments/colli-types/${id}`)

// Fulfilment

export const getDeliveryDateUpdates = options =>
  api.get('/fulfilment/delivery-date-updates', {
    query: options,
  })

export const approveDeliveryDateUpdate = id =>
  api.post(`/fulfilment/delivery-date-updates/${id}/approve`)

// Warehouse

export const getShipmentForScanner = (id, options) =>
  api.get(`/shipments/${id}/for-scanner`, {
    query: options,
  })

export const pickShipment = (
  id,
  confirmTakeOrder,
  subUserId,
  deviceId,
  onConflict
) =>
  api.post(`/shipments/${id}/pick`, {
    body: {
      confirm_takeover: confirmTakeOrder,
      device_id: deviceId,
      sub_user_id: subUserId,
    },
    statusCodeCallback: {
      code: 250000,
      callback: onConflict,
    },
  })

export const bulkPickShipments = (ids, confirmTakeOrder) =>
  api.post(`/shipments/bulk-pick`, {
    body: {
      shipment_ids: ids,
      confirm_takeover: confirmTakeOrder,
    },
  })

export const bulkFulfillShipments = shipments =>
  api.post(`/shipments/bulk-fulfill`, {
    body: {
      shipments,
    },
  })

export const exportShipments = (
  columns: Array<string>,
  filters: Array<Filter>,
  sort: Array<{ key: string, direction: SortDirection }>,
  includeLines: boolean,
  shipmentPerFile: boolean,
  options?: Object
) =>
  api.post(`/shipments/export`, {
    body: {
      columns,
      filters,
      include_lines: includeLines,
      shipment_per_file: shipmentPerFile,
      sort,
      options,
    },
  })

export const invoiceShipment = (
  shipmentId: Id,
  invoiceData: $Shape<Invoice>
): Promise<ApiResponse<Invoice>> =>
  api.post(`/shipments/${shipmentId}/invoice-shipment`, {
    body: {
      invoice_data: invoiceData,
    },
  })

export const invoiceShipmentsBulk = (
  shipments: Array<{ shipment_id: Id }>,
  invoiceData: $Shape<Invoice>
): Promise<ApiResponse<void>> =>
  api.post(`/shipments/convert-to-invoices`, {
    body: {
      shipments,
      invoice_data: invoiceData,
    },
  })

export const cancelShipment = (orderId: Id, shipmentId: Id) =>
  api.post(`/orders/${orderId}/shipments/${shipmentId}/cancel`)

export const registerShipmentPickup = (shipmentId: Id) =>
  api.post(`/shipments/${shipmentId}/register-pickup`)

export const shipOnHoldShipment = (shipmentId: Id) =>
  api.post(`/shipments/${shipmentId}/ship-on-hold`)

export const updateShipment = (shipmentId: Id, data) =>
  api.put(`/shipments/${shipmentId}`, {
    body: {
      shipment: data,
    },
  })

export const calculateSmartShipExpedition = (orders: Array<Object>) =>
  api.post(`/shipments/calculate-smart-ship`, {
    body: {
      orders,
    },
  })

export const createShipmentsBulk = (orders, options = {}) =>
  api.post('/shipments/bulk-v2', {
    body: {
      orders,
    },
    ...options,
  })

export const setShipmentDeliveryNoteColli = (
  shipmentId: Id,
  colliData: Object
): Promise<ApiResponse<null>> =>
  api.post(`/shipments/${shipmentId}/set-delivery-note-colli`, {
    body: {
      colli: colliData,
    },
  })

export const getDeliveredWithoutInvoice = (
  productIdFilter,
  showPerProduct,
  showPerVariant
) =>
  api.post('/shipments/delivered-without-invoice', {
    body: {
      product_id_filter: productIdFilter,
      show_per_product: showPerProduct,
      show_per_variant: showPerVariant,
    },
  })

export const recordShipmentEvents = (
  shipmentId,
  events,
  subUserId,
  deviceId,
  onConflict
) =>
  api.post(`/shipments/${shipmentId}/record-events`, {
    body: {
      events,
      sub_user_id: subUserId,
      device_id: deviceId,
    },
    statusCodeCallback: {
      code: 250000,
      callback: onConflict,
    },
  })

export const getWarehouseEvents = options =>
  api.get(`/warehouse/events`, {
    query: options,
  })

export const recordWarehouseEvents = (
  keyType,
  keyValue,
  events,
  subUserId,
  deviceId,
  onConflict
) =>
  api.post(`/warehouse/events`, {
    body: {
      key_type: keyType,
      key_value: keyValue,
      events,
      sub_user_id: subUserId,
      device_id: deviceId,
    },
    statusCodeCallback: {
      code: 250000,
      callback: onConflict,
    },
  })

// Packing routes

export const getPackingRoutes = (options?: Options) =>
  api.get(`/shipments/packing-routes`, {
    query: options,
  })

export const getPackingRoute = (packingRouteId: Id, options?: Options) =>
  api.get(`/shipments/packing-routes/${packingRouteId}`, {
    query: options,
  })

export const getPackingRouteForScanner = (
  packingRouteId: Id,
  options?: Options
) =>
  api.get(`/shipments/packing-routes/${packingRouteId}/for-scanner`, {
    query: options,
  })

export const autoGeneratePackingRoutes = options =>
  api.post('/shipments/packing-routes/auto-generate', {
    body: options,
  })

export const createPackingRoute = packingRouteData =>
  api.post(`/shipments/packing-routes`, {
    body: {
      packing_route: packingRouteData,
    },
  })

export const updatePackingRoute = (packingRouteId: Id, packingRouteData) =>
  api.put(`/shipments/packing-routes/${packingRouteId}`, {
    body: {
      packing_route: packingRouteData,
    },
  })

export const deletePackingRoute = (packingRouteId: Id) =>
  api.delete(`/shipments/packing-routes/${packingRouteId}`)

export const deliverPackingRouteShipment = (
  packingRouteId: Id,
  shipmentId: Id,
  updates,
  computerId
) =>
  api.post(`/shipments/packing-routes/${packingRouteId}/deliver`, {
    body: {
      packing_route_shipment_id: shipmentId,
      updates,
      computer_id: computerId,
    },
  })

export const deliverAllPackingRoute = (
  packingRouteId: Id,
  shipmentUpdates,
  computerId
) =>
  api.post(`/shipments/packing-routes/${packingRouteId}/deliver-all`, {
    body: {
      shipment_updates: shipmentUpdates,
      computer_id: computerId,
    },
  })

// Warehouse locations

export const ensureWarehouseLocations = (locations: Array<string>) =>
  api.post(`/shipments/warehouse-locations`, {
    body: {
      locations,
    },
  })

// Shipping plan

export const shipShippingPlan = (shippingPlanId, orders, lines) =>
  api.post('/shipments/planner/ship-plan', {
    body: {
      shipping_plan_id: shippingPlanId,
      orders,
      lines,
    },
  })

export const generateShippingPlanReport = options =>
  api.post('/shipments/planner', {
    body: options,
  })

export const addToShippingPlan = (shippingPlanId, inventoryLocationIds, rows) =>
  api.post(`/shipments/planner/add-to-plan`, {
    body: {
      shipping_plan_id: shippingPlanId,
      inventory_location_ids: inventoryLocationIds,
      rows,
    },
  })

export const getShippingPlans = (options?: Options) =>
  api.get(`/shipments/planner/plans`, {
    query: options,
  })

export const getShippingPlan = (shippingPlanId: Id, options?: Options) =>
  api.get(`/shipments/planner/plans/${shippingPlanId}`, {
    query: options,
  })

export const createShippingPlan = shippingPlanData =>
  api.post(`/shipments/planner/plans`, {
    body: {
      shipping_plan: shippingPlanData,
    },
  })

export const updateShippingPlan = (shippingPlanId: Id, shippingPlanData) =>
  api.put(`/shipments/planner/plans/${shippingPlanId}`, {
    body: {
      shipping_plan: shippingPlanData,
    },
  })

export const deleteShippingPlan = (shippingPlanId: Id) =>
  api.delete(`/shipments/planner/plans/${shippingPlanId}`)

// Warehouse locations

export const getWarehouseLocations = (axiosOptions = {}, options = {}) =>
  api.get(`/shipments/warehouse-locations`, {
    ...axiosOptions,
    query: options,
  })

export const getWarehouseLocation = (
  axiosOptions = {},
  warehouseLocationId,
  options = {}
) =>
  api.get(`/shipments/warehouse-locations/${warehouseLocationId}`, {
    ...axiosOptions,
    query: options,
  })

export const createWarehouseLocation = (axiosOptions, location) =>
  api.post('/shipments/warehouse-locations', {
    ...axiosOptions,
    body: {
      location: location,
    },
  })

export const updateWarehouseLocation = (axiosOptions, id, location) =>
  api.put(`/shipments/warehouse-locations/${id}`, {
    ...axiosOptions,
    body: {
      location: location,
    },
  })

export const deleteWarehouseLocation = (axiosOptions, id) =>
  api.delete(`/shipments/warehouse-locations/${id}`, {
    ...axiosOptions,
  })

export const importWarehouseLocations = (
  fileName,
  filePath,
  firstRowIsHeader,
  csvDelimiter
) =>
  api.post(`/shipments/warehouse-locations/import`, {
    body: {
      file_name: fileName,
      file_path: filePath,
      first_row_is_header: firstRowIsHeader,
      csv_delimiter: csvDelimiter,
    },
  })

export const exportWarehouseLocations = () =>
  api.post('/shipments/warehouse-locations/export')

export const downloadWarehouseLocationLabels = labels =>
  api.post(`/shipments/warehouse-locations/download-labels`, {
    body: {
      labels,
    },
  })

export const printWarehouseLocationLabels = (computerId, labels) =>
  api.post(`/shipments/warehouse-locations/print-labels`, {
    body: {
      computer_id: computerId,
      labels,
    },
  })

// Warehouse adjustment codes

export const getWarehouseAdjustmentCodes = (axiosOptions = {}, options = {}) =>
  api.get(`/warehouse-adjustment-codes`, {
    ...axiosOptions,
    query: options,
  })

export const createWarehouseAdjustmentCode = (axiosOptions, code) =>
  api.post('/warehouse-adjustment-codes', {
    ...axiosOptions,
    body: {
      code: code,
    },
  })

export const updateWarehouseAdjustmentCode = (axiosOptions, id, code) =>
  api.put(`/warehouse-adjustment-codes/${id}`, {
    ...axiosOptions,
    body: {
      code: code,
    },
  })

export const deleteWarehouseAdjustmentCode = (axiosOptions, id) =>
  api.delete(`/warehouse-adjustment-codes/${id}`, {
    ...axiosOptions,
  })

export const downloadFreightLabel = (labelType, labelTypeId) =>
  api.get('/warehouse/download-freight-label', {
    query: {
      label_type: labelType,
      label_type_id: labelTypeId,
    },
  })

export const resendShipmentDeliveryNoteLector = (
  shipmentDeliveryNoteId,
  comment,
  newShipmentNumber
) =>
  api.post('/apps/lector/shipments/resend-shipment-delivery-note', {
    body: {
      order_shipment_delivery_note_id: shipmentDeliveryNoteId,
      comment,
      new_shipment_number: newShipmentNumber,
    },
  })
