/* @flow */

import * as React from 'react'
import styled from 'styled-components'

import CustomerFacingDeliveryDate from './Components/CustomerFacingDeliveryDate'
import ProductTableRowsDeliveryList from './Components/ProductTableRowsDeliveryList'

import {
  ALLOCATION_COLOR_GREEN,
  ALLOCATION_COLOR_YELLOW,
  ALLOCATION_COLOR_RED,
  AllocationStatusIndicator,
} from '../../../../../inventory/shared'

import { Tooltip, renderDate } from '../../../../../shared'
import type { ColumnConfig } from '../../types'

type Options = {
  isCustomerFacingDeliveryStatus?: boolean,
}

const delivery_status: (options: Options) => ColumnConfig = ({
  defaultRender,
  label = 'Delivery status',
  lastDeliveryOnly = false,
  isCustomerFacingDeliveryStatus = false,
  usesReservationsV4 = false,
}) => ({
  data_source: 'delivery_status',
  key: 'delivery',
  label,
  render: ({
    brand,
    context,
    data,
    onClearDataCache,
    lines,
    variant,
    value,
    userEntity,
  }) => {
    return (
      <DeliveryStatus
        brand={brand}
        context={context}
        data={data}
        defaultRender={defaultRender}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        lastDeliveryOnly={lastDeliveryOnly}
        lines={lines}
        userEntity={userEntity}
        usesReservationsV4={usesReservationsV4}
        value={value}
        variant={variant}
      />
    )
  },
  style: ({ matrix }) => {
    const style = {}

    if (matrix) {
      style.verticalAlign = 'top'
    }
    if (!isCustomerFacingDeliveryStatus) {
      style.width = '150px'
    }

    return style
  },
})

export default delivery_status

const DeliveryStatus = ({
  brand,
  defaultRender,
  lastDeliveryOnly,
  isCustomerFacingDeliveryStatus,
  value,
  userEntity,
  usesReservationsV4,
}) => {
  const status = value ? value[0] : null

  if (!status) {
    if (defaultRender) {
      return defaultRender()
    }

    return null
  }

  if (status.deliveries.length === 0 && !status.lead_time) {
    return null
  }

  if (
    isCustomerFacingDeliveryStatus &&
    (status.confirmed_delivery || status.estimated_delivery)
  ) {
    return (
      <CustomerFacingDeliveryDate
        brand={brand}
        deliveryDate={status.confirmed_delivery || status.estimated_delivery}
        isEstimated={status.confirmed_delivery == null}
      />
    )
  }

  const allocatedFromIncoming =
    status.allocated_from_production_orders +
    status.allocated_from_production_orders_delivery_notes

  return (
    <DeliveryStatusContainer>
      <ProductTableRowsDeliveryList
        brand={brand}
        lastDeliveryOnly={lastDeliveryOnly}
        isCustomerFacingDeliveryStatus={isCustomerFacingDeliveryStatus}
        status={status}
        userEntity={userEntity}
      />
      {usesReservationsV4 && !isCustomerFacingDeliveryStatus && (
        <DeliveryStatusIndicatorsContainer>
          {status.allocation_green > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_GREEN}
              tip={
                `${status.allocation_green} allocated from inventory\n` +
                (status.inventory_reservations || [])
                  .map(
                    reservation =>
                      `${reservation.quantity} x ${reservation.variant_name}${
                        reservation.batch ? ' (' + reservation.batch + ')' : ''
                      }`
                  )
                  .join('\n')
              }
            />
          )}
          {status.allocation_yellow > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_YELLOW}
              tip={
                `${status.allocation_yellow} allocated from incoming POs` +
                (status.allocation_red_date
                  ? ` (${renderDate(status.allocation_red_date)})`
                  : '')
              }
            />
          )}
          {status.allocation_red > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_RED}
              tip={
                `${status.allocation_red} allocated to late POs or un-allocated` +
                (status.allocation_red_date
                  ? ` (${renderDate(status.allocation_red_date)})`
                  : '')
              }
            />
          )}
        </DeliveryStatusIndicatorsContainer>
      )}
      {!usesReservationsV4 && !isCustomerFacingDeliveryStatus && (
        <DeliveryStatusIndicatorsContainer>
          {status.allocated_from_inventory > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_GREEN}
              tip={
                `${status.allocated_from_inventory} allocated from inventory\n` +
                (status.inventory_reservations || [])
                  .map(
                    reservation =>
                      `${reservation.quantity} x ${reservation.variant_name}${
                        reservation.batch ? ' (' + reservation.batch + ')' : ''
                      }`
                  )
                  .join('\n')
              }
            />
          )}
          {allocatedFromIncoming > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_YELLOW}
              tip={`${allocatedFromIncoming} allocated from incoming POs`}
            />
          )}
          {status.left_to_allocate > 0 && (
            <AllocationStatusIndicator
              color={ALLOCATION_COLOR_RED}
              tip={`${status.left_to_allocate} left to allocate to inventory or POs`}
            />
          )}
        </DeliveryStatusIndicatorsContainer>
      )}
    </DeliveryStatusContainer>
  )
}

const DeliveryStatusContainer = styled.div`
  position: relative;
`

const DeliveryStatusIndicatorsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 1px;
  top: 1px;

  > * {
    margin-left: 3px;
  }
`
