import React from 'react'
import PropTypes from 'prop-types'
import locale from '../modules/globalize'

const FormatNumber = props => {
  const { digits, suffix = '', value } = props
  const formatter = locale.numberFormatter({ maximumFractionDigits: digits })(
    value
  )

  return (
    <span>
      {formatter}
      {suffix}
    </span>
  )
}

FormatNumber.propTypes = {
  value: PropTypes.number,
}

FormatNumber.defaultProps = {
  digits: 2,
}

export default FormatNumber
