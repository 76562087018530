/* @flow */

import React from 'react'
import styled, { css } from 'styled-components'
import { Formik } from 'formik'

import FormatCurrency from '../../../../infrastructure/components/FormatCurrency'
import { PriceInput } from '../../../../infrastructure/components/Formik'

import { IconButton } from './shared'

import type { Product, Variant } from '../../../types'

type Props = {
  context: Object,
  edit?: boolean,
  editable?: boolean,
  editProperty?: string,
  onEdit: (editMode: boolean) => void,
  product: Product,
  showDiscount?: boolean,
  variants: Array<Variant>,
}

const UnitPrice = ({
  assortmentShowUnitPrice = false,
  context,
  edit = false,
  editable = false,
  editProperty = 'net_price',
  onChange,
  onEdit,
  price,
  priceProperty = 'net_price',
  product,
  showDiscount = true,
  variants,
}: Props) => {
  const usePrice = price[0]
  const initialValues = React.useMemo(() => {
    return {
      price: usePrice ? usePrice.net_price : null,
    }
  }, [usePrice])

  const displayPrice = React.useMemo(() => {
    if (!usePrice) {
      return 0
    }

    if (
      assortmentShowUnitPrice &&
      usePrice.assortment_unit_price !== 'undefined'
    ) {
      return usePrice.assortment_unit_price
    }

    if (priceProperty !== 'price_before_discount') {
      return usePrice[priceProperty]
    }

    if (!usePrice.net_price) {
      return usePrice.price_before_discount
    }

    // in case net price is higher than price before discount we want to display net price
    if (
      usePrice.net_price &&
      usePrice.net_price > usePrice.price_before_discount
    ) {
      return usePrice.net_price
    }

    return usePrice.price_before_discount
  }, [assortmentShowUnitPrice, priceProperty, usePrice])

  const onSubmit = React.useCallback(
    (values, { setSubmitting }) => {
      // For Production orders
      // If we are changing the price of existing lines then we are editing the lines data directly. Here
      // we need to use editProperty
      // For splits (creating new splits) we are changing the columnKey and therefore cannot use editProperty
      const splitUpdate = {
        ...price[0],
        net_price: parseFloat(values.price),
        discount_method: 'price',
      }

      // If we show assortment unit price, and we create a new split then we also need to calculate the
      // new assortment_unit_price
      if (splitUpdate.assortment_unit_price !== 'undefined') {
        splitUpdate.assortment_unit_price =
          parseFloat(values.price) / splitUpdate.assortment_quantity
      }

      const lineUpdate = {
        ...price[0],
        [editProperty]: parseFloat(values.price),
        discount_method: 'price',
      }

      onChange(lineUpdate, splitUpdate)

      setSubmitting(false)
    },
    [editProperty, onChange, price]
  )

  return (
    <UnitPriceContainer>
      {editable === true && edit === false && (
        <IconButton size="small" onClick={() => onEdit(true)} type="button">
          <span className="glyphicon glyphicon-pencil" />
        </IconButton>
      )}

      {edit === false && (
        <>
          {usePrice && (
            <div>
              <FormatCurrency currency={context.currency}>
                {displayPrice}
              </FormatCurrency>
              {showDiscount && usePrice.discount_percentage != 0 && (
                <DiscountLabel>{usePrice.discount_percentage}%</DiscountLabel>
              )}
            </div>
          )}
          {!usePrice && <div>0</div>}
        </>
      )}
      {edit === true && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            const wrappedSubmit = e => {
              e.stopPropagation()
              handleSubmit(e)
            }

            return (
              <form onSubmit={wrappedSubmit}>
                <UnitPriceInputContainer>
                  <IconButton
                    size="small"
                    onClick={() => onEdit(false)}
                    type="button"
                  >
                    <span className="glyphicon glyphicon-remove" />
                  </IconButton>

                  <IconButton size="small" disabled={isSubmitting}>
                    {!isSubmitting && (
                      <span className="glyphicon glyphicon-ok" />
                    )}
                    {isSubmitting && <span className="fa fa-spinner fa-spin" />}
                  </IconButton>

                  <StyledPriceInput
                    currency={context.currency}
                    markOnFocus
                    name="price"
                  />
                </UnitPriceInputContainer>
              </form>
            )
          }}
        />
      )}
    </UnitPriceContainer>
  )
}

export default UnitPrice

const UnitPriceContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const UnitPriceInputContainer = styled.div`
  display: flex;
  min-width: 165px;
`

const UnitPriceFormSaveButton = styled.button.attrs({
  type: 'submit',
})`
  background: transparent;
  border: none;
  outline: 0;
`

const StyledPriceInput = styled(PriceInput).attrs({ bsSize: 'sm' })`
  border: none;
  box-shadow: none;
  border-bottom: 1px dashed #d8d8d8;
  outline: 0;
  text-align: right;

  &:focus {
    border-color: inherit;
    outline: 0;
    box-shadow: none;
  }
`

const DiscountLabel = styled.div`
  color: red;
  font-size: 9px;
`
