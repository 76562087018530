/* @flow */

import * as React from 'react'
import xor from 'lodash/xor'

import {
  generateSections,
  addLinesToSections,
  aggregateColumnsIntoProductTotals,
  createColumnKey,
} from './data'

export const useProductTableRows = (
  assortmentTotalsMode,
  attributes,
  columns,
  data,
  dataConfigs,
  filterVariantsAlwaysRequireLine,
  filterVariantsWithoutLines,
  filterVariantsByLinesAndData,
  horizontalAttribute,
  lines,
  matrix,
  preview,
  product,
  rows,
  splits,
  tableData,
  variants,
  verticalAttributes,
  sort
) => {
  const iterated = React.useRef(0)
  const [generatedSectionData, setGeneratedSections] = React.useState({
    columnTotals: {},
    generatedSections: [],
    horizontalAttributeCodes: {},
    horizontalAttributeValues: new Set(),
    variants: [],
  })

  const {
    columnTotals,
    generatedSections,
    horizontalAttributeCodes,
    horizontalAttributeValues,
    variants: lastGeneratedVariants,
  } = generatedSectionData

  const linesAsArray = React.useMemo(() => {
    return lines ? Object.values(lines) : []
  }, [lines])

  const filteredVariants = React.useMemo(() => {
    return filterOutVariantsWithZeroLinesOrByFilterFunction(
      lastGeneratedVariants,
      variants,
      linesAsArray,
      filterVariantsAlwaysRequireLine,
      filterVariantsWithoutLines,
      filterVariantsByLinesAndData,
      data,
      dataConfigs
    )
  }, [
    lastGeneratedVariants,
    variants,
    linesAsArray,
    filterVariantsAlwaysRequireLine,
    filterVariantsWithoutLines,
    filterVariantsByLinesAndData,
    data,
    dataConfigs,
  ])

  const sections = React.useMemo(() => {
    return generateSections(
      attributes,
      columns,
      data,
      dataConfigs,
      horizontalAttribute,
      matrix,
      product,
      rows,
      tableData,
      filteredVariants,
      verticalAttributes,
      sort,
      variants
    )
  }, [
    attributes,
    columns,
    data,
    dataConfigs,
    horizontalAttribute,
    matrix,
    product,
    rows,
    tableData,
    filteredVariants,
    verticalAttributes,
    sort,
    variants,
  ])

  const sectionsWithLines = React.useMemo(() => {
    return addLinesToSections(
      columns,
      linesAsArray,
      rows,
      sections.generatedSections,
      splits,
      matrix,
      tableData,
      preview,
      assortmentTotalsMode
    )
  }, [
    assortmentTotalsMode,
    columns,
    linesAsArray,
    rows,
    sections.generatedSections,
    splits,
    matrix,
    tableData,
    preview,
  ])

  const updatedColumnTotals = React.useMemo(() => {
    return aggregateColumnsIntoProductTotals(sectionsWithLines, columns)
  }, [sectionsWithLines, columns])

  React.useEffect(() => {
    setGeneratedSections({
      ...sections,
      columnTotals: updatedColumnTotals,
      generatedSections: sectionsWithLines,
    })
  }, [sections, updatedColumnTotals, sectionsWithLines, setGeneratedSections])

  return generatedSectionData
}

const filterOutVariantsWithZeroLinesOrByFilterFunction = (
  lastGeneratedVariants,
  variants,
  linesAsArray,
  filterVariantsAlwaysRequireLine,
  filterVariantsWithoutLines,
  filterVariantsByLinesAndData,
  data,
  dataConfig
) => {
  if (!filterVariantsWithoutLines && !filterVariantsAlwaysRequireLine) {
    return variants
  }

  const showAllVariantsWithALine =
    !filterVariantsWithoutLines && filterVariantsAlwaysRequireLine

  const passedVariants = variants.filter(variant => {
    const linesOfVariant = linesAsArray.filter(l => l.variant_id == variant.id)

    let shouldIncludeVariant = linesOfVariant.length > 0
    // filterVariantsAlwaysRequireLine: e.g. in new order module we pre-fetch lines to determine which lines "we can make"
    // when "expanding a product" we should then only show the lines that we can purchase
    if (filterVariantsByLinesAndData && !showAllVariantsWithALine) {
      shouldIncludeVariant = filterVariantsByLinesAndData({
        lines: linesOfVariant,
        data,
        dataConfig,
        variant,
      })
    }

    return shouldIncludeVariant
  })

  const passedVariantIds = passedVariants.map(v => v.id)

  const lastGeneratedVariantIds = lastGeneratedVariants.map(v => v.id)

  if (xor(passedVariantIds, lastGeneratedVariantIds).length === 0) {
    return lastGeneratedVariants
  }

  return passedVariants
}
