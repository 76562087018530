/* @flow */

import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import LineText from '../../LineText'

import type { ColumnConfig } from '../../types'

const line_text: (options: Options) => ColumnConfig = ({
  editable = false,
  property = 'text',
  rows,
}) => ({
  // if there is no default value then the row will unmount and mount all the time
  default_value: [{ text: null }],
  editable: editable,
  key: 'text',
  label: 'Text',
  rows: rows,
  split_by_values: true,
  value: ({ line }) => [{ text: line ? line[property] : null }],
  render: ({
    config,
    edit,
    onColumnValueChange,
    onEdit,
    product,
    variants,
    value,
  }) => {
    return (
      <LineText
        edit={edit}
        editable={config.editable}
        onChange={value => onColumnValueChange({ [property]: value.text })} // Intercept and change the property if needed
        onEdit={onEdit}
        product={product}
        value={value}
        variants={variants}
      />
    )
  },
})

export default line_text
